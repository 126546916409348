import React from "react"
import Header from "./Header"
import SideBar from "./SideBar"

function Layout({ children,label }) {
	return (
		<div className='flex w-full overflow-hidden overflow-y-auto  '>
			<SideBar />
			<div className='flex relative w-full'>
				<Header />
				<div className='absolute w-full top-20'>{children}</div>
			</div>
		</div>
	)
}

export default Layout
