/** @format */

import React, { useState, useEffect } from "react";

import "../atoms/purchase.css";
import Layout from "../atoms/layout";
import Card from "../atoms/Card";
import trade from "../assets/trade.png";
import icon2 from "../assets/icontwo.png";
import RedButton from "../atoms/RedButton";
import f1 from "../assets/f1.svg";
import Alert from "../atoms/Alert";
import Suspension from "../atoms/layout/Suspension";
import Modal from "../atoms/Modal";
import Card2 from "../atoms/Card2";
import axios from "axios";
import BlueBotton from "../atoms/BlueBotton";
import UnsuspendAlert from "../atoms/UnsuspendAlert";

const ProductDetails = ({ onRowSelection, onEmployeeNameClick }) => {
	const [selectedRows, setSelectedRows] = useState([]);
	const [checkedRowsData, setCheckedRowsData] = useState([]);
	const [processedData, setProcessedData] = useState();
	const [modal, setModal] = useState(false);
	const [unModal, setUnModal] = useState(false);
	const [suspend, setSuspend] = useState(false);
	const [unSuspend, setUnSuspend] = useState(false);
	const [sucess, setSucess] = useState(false);
	const [unSucess, setUnSucess] = useState(false);
	const [id, setId] = useState("");
	const [userData, setUserData] = useState("");
	const [loading, setLoading] = useState(false);
	const [pending, setPending] = useState("");
	const [completed, setCompleted] = useState("");
	const openModal = () => {
		setModal(true);
	};
	const openUnModal = () => {
		setUnModal(true);
	};
	const closeModal = () => {
		setModal(false);
	};
	const closeUnModal = () => {
		setUnModal(false);
	};
	const openSuspend = () => {
		setSuspend(true);
		setModal(false);
	};
	const openUnSuspend = () => {
		setUnSuspend(true);
		setUnModal(false);
	};
	const closeSuspend = () => {
		setSuspend(false);
	};
	const closeUnSuspend = () => {
		setUnSuspend(false);
	};
	const openSucess = () => {
		setSucess(true);
		setSuspend(false);
		console.log("hrlllo");
	};
	const openUnSucess = () => {
		setUnSucess(true);
		setUnSuspend(false);
		console.log("hrlllo");
	};
	const closeSucess = () => {
		setSucess(false);
	};
	const closeUnSucess = () => {
		setUnSucess(false);
	};
	const data = [
		{
			customerName: "John Doe",
			orderDate: "2023-09-15",
			orderType: "Online",
			trackingID: "TRK12345",
			orderTotal: "₦2,500",
			action: "edit",
			status: "Pending",
		},
		{
			customerName: "Jane Smith",
			orderDate: "2023-09-14",
			orderType: "In-Store",
			trackingID: "TRK67890",
			orderTotal: "₦1,800",
			action: "delete",
			status: "Completed",
		},
		// Add more data entries as needed
	];
	const [token, setToken] = useState("");
	const getEntries = () => {
		const result = sessionStorage.getItem("myToken");

		const newToken = JSON.parse(result);
		setToken(newToken);
	};
	useEffect(() => {
		getEntries();
	}, [token]);
	const toggleRowSelection = (index, rowData) => {
		const updatedRows = selectedRows.includes(index)
			? selectedRows.filter((rowIndex) => rowIndex !== index)
			: [...selectedRows, index];

		setSelectedRows(updatedRows);

		const updatedCheckedRowsData = updatedRows.map(
			(rowIndex) => data[rowIndex]
		);

		setCheckedRowsData(updatedCheckedRowsData);
		console.log(updatedCheckedRowsData, "checkedRowsData");
		sessionStorage.setItem("selected", JSON.stringify(updatedCheckedRowsData));

		onRowSelection(updatedCheckedRowsData); // Call the onRowSelection with updatedRows
	};
	const handleActionChange = (selectedAction, row) => {
		// Implement your logic here based on the selected action and the corresponding row
	};
	useEffect(() => {
		const updatedCheckedRowsData = selectedRows.map(
			(rowIndex) => data[rowIndex]
		);
		setCheckedRowsData(updatedCheckedRowsData);
	}, []);
	useEffect(() => {
		const updatedCheckedRowsData = selectedRows.map(
			(rowIndex) => data[rowIndex]
		);

		setProcessedData(updatedCheckedRowsData);
		sessionStorage.setItem(
			"checkedRowsData",
			JSON.stringify(updatedCheckedRowsData)
		);
	}, []);

	// ...
	useEffect(() => {
		// Retrieve stored checkedRowsData from sessionStorage
		const savedCheckedRowsData = JSON.parse(
			sessionStorage.getItem("selectedIDs")
		);
		if (savedCheckedRowsData) {
			setId(savedCheckedRowsData);
			console.log(
				"Retrieved Checked Rows Data on Page B:",
				savedCheckedRowsData
			);
		}
	}, []);
	const ProductDetails = async () => {
		try {
			const API_URL = `https://flashserve-api-761656c703cc.herokuapp.com/api/v1/admin/product/${id[0]}`;
			const config = {
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			};
			const response = await axios.get(API_URL, config);
			console.log(response?.data?.data, "hello setups");
			setUserData(response?.data?.data);
			const filteredPending = response?.data?.data?.vendor_orders.filter(
				(item) => item.order_status === "pending"
			);
			const filteredCompleted = response?.data?.data?.vendor_orders.filter(
				(item) => item.order_status === "completed"
			);
			setPending(filteredPending);
			setCompleted(filteredCompleted);
		} catch (error) {
			console.error(error, "errorrr"); // Use console.error for error messages
		}
	};

	useEffect(() => {
		ProductDetails();
	}, [id]);
	const formatDateAndTime = (dateTimeString) => {
		const dateTime = new Date(dateTimeString);

		// Separate date and time
		const date = dateTime.toLocaleDateString(undefined, {
			year: "numeric",
			month: "long",
			day: "numeric",
		});
		const time = dateTime.toLocaleTimeString(undefined, {
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
		});

		return { date, time };
	};

	return (
		<Layout label={"Customer"}>
			<div className="flex justify-between mt-5 mx-10 gap-4">
			
				
			</div>
			<Alert
				onClick={openSuspend}
				title={"Suspend Vendor"}
				content={
					<div className="text-[#64748B] font-semibold text-[16px] leading-6">
						Are you sure you want to suspend this vendor
					</div>
				}
				isOpen={modal}
				onClose={closeModal}
			/>
			<UnsuspendAlert
				onClick={openUnSuspend}
				title={"Unsuspend Vendor"}
				content={
					<div className="text-[#64748B] font-semibold text-[16px] leading-6">
						Are you sure you want to unSuspend this vendor
					</div>
				}
				isOpen={unModal}
				onClose={closeUnModal}
			/>
			{/* <Suspension
				label={loading ? "Loading....." : "SUSPEND"}
				title={"Suspend Vendor"}
				isOpen={suspend}
				onClick={SuspendUser}
				onClose={closeSuspend}
			/> */}
			{/* <NoSuspension
				label={loading ? "Loading....." : "UNSUSPEND"}
				title={"UnSuspend Vendor"}
				isOpen={unSuspend}
				onClick={UnSuspendUser}
				onClose={closeUnSuspend}
			/> */}
			<Modal
				isOpen={sucess}
				onClose={closeSucess}
				title={"User Account suspended successfully"}
			/>
			<Modal
				isOpen={unSucess}
				onClose={closeUnSucess}
				title={"You have successfully unsuspended this vendor"}
			/>
			<div className="mx-10    mt-10 mb-10 flex gap-8">
				<Card2
					imageSource={icon2}
					description="Phone Number"
					description2="Email"
					iconClass="fas fa-info-circle" // Replace with n
					iconLabel="Information"
					numbers={userData?.businessOwnerdetails?.email}
					amount={userData?.businessOwnerdetails?.phone}
				/>
				<Card
					imageSource={f1}
					description="Business Address"
					iconClass="fas fa-info-circle"
					iconLabel="Information"
					amount={userData?.bussinessDetails?.address}
				/>
				{/* <Card
					imageSource={trade}
					description="Total Revenue"
					amount="₦4,000,000"
					iconClass="fas fa-info-circle" 
					iconLabel="Information"
				/> */}
				<Card
					imageSource={icon2}
					description="Total Sales"
					iconClass="fas fa-info-circle" // Replace with n
					iconLabel="Information"
					amount="₦0"
				/>
			</div>
			{/* <div className="mx-10    mt-10 mb-10 flex gap-8">
			

				<Card
					imageSource={trade}
					description="All Orders"
					description2="Pending"
					description3="Completed"
					descriptionSub3={completed?.length}
					numbers={pending?.length}
					amount={userData?.vendor_orders?.length}
					iconClass="fas fa-info-circle" 
					iconLabel="Information"
				/>
			</div> */}
			<div className="rounded-lg shadow-lg bg-white my-20 mx-10">
				<div className="px-10 py-5">
					<div className=" bg-white   px-10 py-5 w-[100%] justify-between ">
						<div className=" bg-white w-full justify-between flex h-full">
							<div className="flex w-3/4">
								<form>
									<label
										for="default-search"
										class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white w-full"
									>
										Search
									</label>
									<div class="relative w-96">
										<div class="absolute w-full inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
											<svg
												aria-hidden="true"
												class="w-5 h-5 text-gray-500 dark:text-gray-400"
												fill="none"
												stroke="currentColor"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
												></path>
											</svg>
										</div>
										<input
											type="search"
											id="default-search"
											class="block w-full p-2 pl-10  text-sm text-gray-900   rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-[#CBD5E1] dark:bg-[#ffffff] dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 border-2 border-[#CBD5E1] "
											placeholder="Search for employee or job title"
											required
										/>
									</div>
								</form>
							</div>
							<div className="flex w-1/4">
								<div className="relative text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
									<select
										className="absolute inset-y-0 left-0 pl-4 pr-2 text-sm text-gray-900 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-[#CBD5E1] "
										onChange={(e) => handleActionChange(e.target.value)}
									>
										<option value="">Select Action</option>
										<option value="edit">Edit</option>
										<option value="delete">Delete</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="grid grid-cols-8 p-2 bg-gray-200 py-8 text-[#475569] font-semibold">
					<div className="cell ml-3">
						<input type="checkbox" />
					</div>
					<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
						Order Date
					</div>
					<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
						Quantity
					</div>
					<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
						Price
					</div>
					<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
						Order Id
					</div>
					<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
						Phone
					</div>
					<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
						Address
					</div>

					<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider mx-10 ">
						Status
					</div>
				</div>
				{userData?.vendor_orders?.map((row, index) => {
					return (
						<div className={`grid grid-cols-8 py-4 border-b`} key={index}>
							<div className="cell ml-3">
								<input
									type="checkbox"
									checked={selectedRows.includes(index)}
									onChange={() => toggleRowSelection(index, row)}
								/>
							</div>
							<div className=" gap-2 flex text-left text-sm">
								<img
									className="w-9 h-9 rounded-md"
									src={row.product_details?.photos[0]}
									alt={"imageAlt"}
								/>
								<button
									className="focus:outline-none text-[#2563EB] font-medium"
									onClick={() => {
										sessionStorage.setItem(
											"selectedIDs",
											JSON.stringify([row.id])
										);
									}}
								>
									{row.product_details?.name}
								</button>
							</div>
							<div className="text-left text-sm">
								<button
									className="focus:outline-none font-medium"
									onClick={() => {
										onEmployeeNameClick(row.employeeName);
										sessionStorage.setItem(
											"selectedIDs",
											JSON.stringify([row.id])
										);
									}}
								>
									{row.quantity}
								</button>
							</div>
							<div className="text-left text-sm">
								₦ {row?.product_details?.price}
							</div>
							<div className="text-left text-sm">{row.id}</div>
							<div className="text-left text-sm">
								{row.customer_address?.phone}
							</div>
							<div className="text-left text-sm">
								{row.customer_address?.address}
							</div>

							<div className="text-[#007BE0]   text-[12px] font-semibold leading-7 mx-10 flex items-center justify-center rounded-lg">
								{row.order_status}
							</div>
						</div>
					);
				})}
				<div className="p-5 flex items-center justify-between mt-5">
					{/* ... (Pagination and Show rows elements remain the same) */}
				</div>
			</div>
		</Layout>
	);
};

export default ProductDetails;
