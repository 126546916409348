/** @format */

import React from "react";

const MultiCard = ({ userData }) => {
	console.log(userData, "dattatat");
	return (
		<div class=" mx-auto bg-white flex-1  p-4 rounded-lg shadow-md">
			<div class=" flex-1  p-4 rounded-lg ">
				<h2 class="text-[16px] font-semibold mb-4 text-left">
					Customer Details
				</h2>
				<hr />
				<div class="flex flex-cols-3 gap-4 mt-10">
					<div class="flex-1 items-center h-200">
						<p className="grid">
							<strong>First Name:</strong>
							{userData?.customer_details?.username}
						</p>

						<p className="grid">
							<strong>Adress:</strong>
							{userData?.customer_address?.address}
						</p>
					</div>
					<div class="flex-1 items-center">
						<p className="grid">
							<strong>Phone Number:</strong> {userData?.customer_details?.phone}
						</p>

						<p className="grid">
							<strong>Order Type:</strong> Customer
						</p>
                        <p className="grid">
							<strong>Email:</strong> {userData?.customer_details?.email}
						</p>
					</div>
					
				</div>
			</div>
		</div>
	);
};

export default MultiCard;
