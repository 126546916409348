/** @format */

import React, { useState } from "react";
import cans from "../assets/cans copy.png"; 
// Import the icon's CSS
import { useNavigate } from "react-router-dom";
import RedButton from "./RedButton";
import BlueBotton from "./BlueBotton";
// import BlueBotton from "./BlueBotton";
// import LineButton from "./LineButton";
function UnsuspendAlert({ isOpen, onClose, children,title, content,label,onClick  }) {
	const navigate = useNavigate();
	if (!isOpen) return null;



	return (
		<div className="p-5 fixed inset-0 flex items-center justify-center z-50">
			<div className="modal-overlay absolute inset-0 bg-black opacity-50"></div>

			<div className="modal-content  bg-white  rounded-lg shadow-md relative p-10 flex ">
				<div>
					<p className="text-[24px] font-bold text-[#15131B] leading-5 mb-5">
						{/* Confirm run all employee payroll? */}
						{title}
					</p>
					<div>
						<span className="text-[18px] font-normal text-[#475569] leading-5 ">
						
							{content}
						</span>
					</div>
					<div className="flex mt-10 gap-10">
						<RedButton onClick={onClose} label={"NOT YET"} />
						<BlueBotton
							onClick={onClick}
							label={"YES, PROCEED"}
							
						/>
					</div>
				</div>

				<button
					className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
					onClick={onClose}
				>
					<img width="20" height="20" src={cans} alt="rightarr" />
				</button>
				{children}
			</div>
		</div>
	);
}

export default UnsuspendAlert;
