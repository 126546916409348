/** @format */
import React, { useState, useEffect } from "react";
import "../atoms/purchase.css";
import Layout from "../atoms/layout";
import ride2 from "../assets/car2.svg";
import { useNavigate } from "react-router-dom";
import BlueCard from "../atoms/BlueCard";
import { getAllProducts } from "../services/requests";
import Loader from "../atoms/Loader";
import Sidebar from "../atoms/layout/SideBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
const ProductPage = () => {
	const [user, setUser] = useState([]);
	const [loading, setLoading] = useState(true);

	async function getProducts() {
		try {
			const result = sessionStorage.getItem("myToken");
			const newToken = JSON.parse(result);
			const { data } = await getAllProducts(newToken);
			setUser(data?.data);
			setLoading(false);
		} catch (err) {
			console.error(err);
			setLoading(false);
		}
	}
	useEffect(() => {
		getProducts();
	}, []);
	const formatDateAndTime = (dateTimeString) => {
		const dateTime = new Date(dateTimeString);

		// Separate date and time
		const date = dateTime.toLocaleDateString(undefined, {
			year: "numeric",
			month: "long",
			day: "numeric",
		});
		const time = dateTime.toLocaleTimeString(undefined, {
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
		});

		return { date, time };
	};

	const [search, setSearch] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const handleSearch = (event) => {
		const searchText = event.target.value;
		setSearch(searchText);

		const filteredData = user?.filter((user) =>
			user?.name?.toLowerCase().includes(searchText.toLowerCase())
		);

		setSearchResults(filteredData);
	};
	const [isSmallScreen, setIsSmallScreen] = useState(false);

	// Check screen size on mount and on window resize
	const handleResize = () => {
		setIsSmallScreen(window.innerWidth <= 768); // Adjust the threshold according to your needs
	};

	useEffect(() => {
		handleResize(); // Initial check
		window.addEventListener("resize", handleResize);

		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	const itemsPerPage = 4; // Change this value based on your requirement
	const [currentPage, setCurrentPage] = useState(0);

	const paginatedData = user
		?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
		.map((item) => {
			return {
				productDetails: item,
			};
		});
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const totalPages = Math.ceil(user?.length / itemsPerPage);
	return (
		<div>
			{isSmallScreen ? (
				// Render alternative layout for small screens
				<div>
					<Sidebar />
					{loading ? (
						<Loader />
					) : (
						<div>
							<div className="flex justify-end mt-5 mx-10 gap-5"></div>
							<div className="mx-10    mt-10 mb-10 flex gap-8">
								<BlueCard
									imageSource={ride2}
									description="Total Products"
									amount={user?.length}
									iconClass="fas fa-info-circle"
									iconLabel="Information"
								/>
							</div>
							<div className="rounded-lg shadow-lg bg-white ">
								<div className="">
									<div className=" bg-white   px-10 py-5 w-[100%] justify-between ">
										<div className=" bg-white w-full justify-between  grid lg:flex h-full">
											<div className="flex w-full">
												<form>
													<label
														for="default-search"
														class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white w-full"
													>
														Search
													</label>
													<div class="relative w-full">
														<div class="absolute w-full inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
															<svg
																aria-hidden="true"
																class="w-5 h-5 text-gray-500 dark:text-gray-400"
																fill="none"
																stroke="currentColor"
																viewBox="0 0 24 24"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	stroke-linecap="round"
																	stroke-linejoin="round"
																	stroke-width="2"
																	d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
																></path>
															</svg>
														</div>
														<input
															value={search}
															onChange={handleSearch}
															type="search"
															id="default-search"
															class="block w-full p-2 pl-10  text-sm text-[#000000] rounded   dark:focus:ring-blue-500 border-2 border-[#CBD5E1] "
															placeholder="Search"
														/>
													</div>
												</form>
											</div>
											<div className="flex w-1/4 mt-5">
												<div className="relative text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
													Total Products
													<span>({user?.length})</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="grid grid-cols-3 p-2 bg-gray-200 py-8 text-[#475569] font-semibold pl-10">
									<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
										Product Name
									</div>
									<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
										Product Price
									</div>
						
									<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
										Date Uploaded
									</div>
						
								</div>
								{search === ""
									? paginatedData?.map((row, index) => {
											console.log(row, "hey");
											return (
												<div
													className={`grid grid-cols-3 py-4 border-b ml-10`}
													key={index}
												>
													<div className=" gap-2 flex text-left text-sm">
														<img
															className="w-9 h-9 rounded-md"
															src={row.photos[0]}
															alt={"imageAlt"}
														/>
														<button
															className="focus:outline-none text-[#2563EB] font-medium"
															onClick={() => {
																sessionStorage.setItem(
																	"selectedIDs",
																	JSON.stringify([row?.id])
																);
																// navigate("/productsDetails");
															}}
														>
															{row?.name}
														</button>
													</div>
													<div className="gap-2 flex text-left text-sm">
														₦{row?.price}
													</div>

													<div className="text-left text-sm">
														{formatDateAndTime(row?.createdAt).date}
													</div>
													{/* <div className="text-left text-sm">
								{row.businessDetails?.address}
							</div> */}
													{/* <div className="text-left text-sm">{row.orderTotal}</div> */}

													{/* <div className="text-[#007BE0]   text-[12px] font-semibold leading-7 mx-10">
								{row.status}
							</div> */}
												</div>
											);
									  })
									: searchResults?.map((row, index) => {
											console.log(row, "hey");
											return (
												<div
													className={`grid grid-cols-3 py-4 border-b ml-10`}
													key={index}
												>
													{/* <div className="cell ml-3">
							<input
								type="checkbox"
								checked={selectedRows.includes(index)}
							/>
						</div> */}
													<div className=" gap-2 flex text-left text-sm">
														<img
															className="w-9 h-9 rounded-md"
															src={row.photos[0]}
															alt={"imageAlt"}
														/>
														<button
															className="focus:outline-none text-[#2563EB] font-medium"
															onClick={() => {
																sessionStorage.setItem(
																	"selectedIDs",
																	JSON.stringify([row?.id])
																);
																// navigate("/productsDetails");
															}}
														>
															{row?.name}
														</button>
													</div>
													<div className="gap-2 flex text-left text-sm">
														₦{row?.price}
													</div>
													{/* <div className="gap-2 flex text-left text-sm">
							<img
								className="w-9 h-9 rounded-md"
								src={row.businessDetails.businessPhoto}
								alt={"imageAlt"}
							/>
							{row?.businessOwnerDetails?.username}
						</div> */}

													<div className="text-left text-sm">
														{formatDateAndTime(row?.createdAt).date}
													</div>
													{/* <div className="text-left text-sm">
							{row.businessDetails?.address}
						</div> */}
												</div>
											);
									  })}
							</div>
						</div>
					)}
				</div>
			) : (
				// Render your default layout for larger screens
				<div>
					{loading ? (
						<Loader />
					) : (
						<Layout>
							<div className="flex justify-end mt-5 mx-10 gap-5"></div>
							<div className="mx-10    mt-10 mb-10 flex gap-8">
								<BlueCard
									imageSource={ride2}
									description="Total Products"
									amount={user?.length}
									iconClass="fas fa-info-circle"
									iconLabel="Information"
								/>
							</div>
							<div className="rounded-lg shadow-lg bg-white my-20 mx-10">
								<div className="px-10 py-5">
									<div className=" bg-white   px-10 py-5 w-[100%] justify-between ">
										<div className=" bg-white w-full justify-between flex h-full">
											<div className="flex w-3/4">
												<form>
													<label
														for="default-search"
														class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white w-full"
													>
														Search
													</label>
													<div class="relative w-96">
														<div class="absolute w-full inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
															<svg
																aria-hidden="true"
																class="w-5 h-5 text-gray-500 dark:text-gray-400"
																fill="none"
																stroke="currentColor"
																viewBox="0 0 24 24"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	stroke-linecap="round"
																	stroke-linejoin="round"
																	stroke-width="2"
																	d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
																></path>
															</svg>
														</div>
														<input
															value={search}
															onChange={handleSearch}
															type="search"
															id="default-search"
															class="block w-full p-2 pl-10  text-sm text-[#000000] rounded   dark:focus:ring-blue-500 border-2 border-[#CBD5E1] "
															placeholder="Search for employee or job title"
														/>
													</div>
												</form>
											</div>
											<div className="flex w-1/4">
												<div className="relative text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
													Total Products
													<span>({user?.length})</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="grid grid-cols-4 p-2 bg-gray-200 py-8 text-[#475569] font-semibold pl-10">
									<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
										Product Name
									</div>
									<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
										Product Price
									</div>
									<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
										Vendor Name
									</div>
									<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
										Date Uploaded
									</div>
									{/* <div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
										Location of Vendor
									</div> */}
								</div>
								{search === ""
									? paginatedData?.map((row, index) => {
											console.log(row, "hey");
											return (
												<div
													className={`grid grid-cols-4 py-4 border-b ml-10`}
													key={index}
												>
												
													<div className=" gap-2 flex text-left text-sm">
														<img
															className="w-9 h-9 rounded-md"
															src={row.productDetails.photos[0]}
															alt={"imageAlt"}
														/>
														<button
															className="focus:outline-none text-[#2563EB] font-medium"
															onClick={() => {
																sessionStorage.setItem(
																	"selectedIDs",
																	JSON.stringify([row?.productDetails.id])
																);
																// navigate("/productsDetails");
															}}
														>
															{row?.productDetails.name}
														</button>
													</div>
													<div className="gap-2 flex text-left text-sm">
														₦{row?.productDetails.price}
													</div>
													<div className="gap-2 flex text-left text-sm">
														<img
															className="w-9 h-9 rounded-md"
															src={row.productDetails.businessDetails.businessPhoto}
															alt={"imageAlt"}
														/>
														{row?.productDetails.businessOwnerDetails?.username}
													</div>

													<div className="text-left text-sm">
														{formatDateAndTime(row?.productDetails.createdAt).date}
													</div>
													<div className="text-left text-sm">
														{row.productDetails.businessDetails?.address}
													</div>
													{/* <div className="text-left text-sm">{row.orderTotal}</div> */}

													{/* <div className="text-[#007BE0]   text-[12px] font-semibold leading-7 mx-10">
												{row.status}
											</div> */}
												</div>
											);
									  })
									: searchResults?.map((row, index) => {
											console.log(row, "hey");
											return (
												<div
													className={`grid grid-cols-4 py-4 border-b`}
													key={index}
												>
													<div className="cell ml-3">
														<input type="checkbox" />
													</div>
													<div className=" gap-2 flex text-left text-sm">
														<img
															className="w-9 h-9 rounded-md"
															src={row.photos[0]}
															alt={"imageAlt"}
														/>
														<button
															className="focus:outline-none text-[#2563EB] font-medium"
															onClick={() => {
																sessionStorage.setItem(
																	"selectedIDs",
																	JSON.stringify([row?.id])
																);
																// navigate("/productsDetails");
															}}
														>
															{row?.name}
														</button>
													</div>
													<div className="gap-2 flex text-left text-sm">
														<img
															className="w-9 h-9 rounded-md"
															src={row.businessDetails.businessPhoto}
															alt={"imageAlt"}
														/>
														{row?.businessOwnerDetails?.username}
													</div>
													<div className="text-left text-sm">
														{formatDateAndTime(row?.createdAt).date}
													</div>
													<div className="text-left text-sm">
														{row.businessDetails?.address}
													</div>
												</div>
											);
									  })}
								<div className="mb-5">
									<button
										onClick={() => handlePageChange(currentPage - 1)}
										disabled={currentPage === 0}
									>
										<FontAwesomeIcon icon={faChevronLeft} /> Previous
									</button>

									<span>{`Page ${currentPage + 1} of ${totalPages}`}</span>

									<button
										onClick={() => handlePageChange(currentPage + 1)}
										disabled={currentPage === totalPages - 1}
									>
										Next <FontAwesomeIcon icon={faChevronRight} />
									</button>
								</div>
							</div>
						</Layout>
					)}
				</div>
			)}
		</div>
	);
};

export default ProductPage;
