/** @format */

import React from "react";

const Card2 = ({
	imageSource,
	imageAlt,

	description,
	description2,
	description3,

	descriptionSub3,
	amount,
	numbers,
	onClick, // Add onClick prop
}) => {
	return (
		<div
			onClick={onClick}
			className=" rounded-md h-53 flex-1 overflow-hidden shadow-lg bg-[#ffffff]  mb-10 sm:mb-4 sm:grid"
		>
			<img className="my-3 mx-3 h-20 w-20 r-5" src={imageSource} alt={imageAlt} />
			<div className="w-full">
				<div className="grid">
					<div>
						<p className="text-[#4F4F4F] text-[14px]">
							{description}
							<p className="text-[#333333] text-[16px] font-semibold leading-7">
								{amount}
							</p>
						</p>
					</div>

					<div>
						<p className="text-[#4F4F4F] text-[14px]">{description2}</p>
						<p className="text-[#333333] text-[14px] font-semibold leading-7 ">
							{numbers}
						</p>
					</div>
					<div>
						<p className="text-[#4F4F4F] text-[14px]">{description3}</p>
						<p className="text-[#333333] text-[14px] font-semibold leading-7">
							{descriptionSub3}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Card2;
