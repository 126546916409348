/** @format */

import "./App.css";
import Routing from "./route/Routing";

function App() {
	return (
		<div className="App">
			<Routing />
		</div>
	);
}

export default App;
