/** @format */

import React, { useState } from "react";
import bg from "../assets/bg.png";
import logo from "../assets/logo.png";
import BlueBotton from "../atoms/BlueBotton";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { IconName, IoMdEye, IoMdEyeOff } from "react-icons/io";
function Login() {
	const navigate = useNavigate();
	const Dashboard = () => {};
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [passwordVissibility, setPasswordVissibility] = useState(true);
	const managePasswordVisibility = () => {
		setPasswordVissibility(!passwordVissibility);
	};

	const Login = async (e) => {
		setLoading(true);

		try {
			const API_URLS = `https://flashserve-api-761656c703cc.herokuapp.com/api/v1/auth/admin/login`;
			const config = {
				headers: {
					"Content-Type": "application/json",
				},
			};
			const payload = {
				email,
				password,
			};
			const response = await axios.post(API_URLS, payload, config);
			navigate("/dashboard");
			console.log(response, "user");
			console.log(response?.data?.token, "token");
			sessionStorage.setItem("myToken", JSON.stringify(response?.data?.token));
			sessionStorage.setItem("mydata", JSON.stringify(response?.data));
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error, "token error");
		}
	};
	return (
		<div
			style={{
				backgroundImage: `url(${bg})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				width: "100vw",
				height: "100vh",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<div className="bg-[#ffffff] p-8 rounded-lg lg:w-1/2">
				<div className="flex justify-center ">
					<img src={logo} alt="alt" className="h-[73px] w-[90px] " />
				</div>
				<h2 className="leading-6 text-[20px] text-[#4F4F4F]  mb-4 font-semibold">
					Admin Login
				</h2>

				<div className="mb-4">
					<label
						className="text-[#344054] text-left block mb-1 leading-5 text-sm"
						htmlFor="email"
					>
						Email
					</label>
					<input
						className="w-full px-3 py-2 border rounded"
						type="email"
						id="email"
						name="email"
						value={email}
						required
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>
				<div className="mb-4">
					<label
						className="text-[#344054] text-left block mb-1 leading-5 text-sm"
						htmlFor="password"
					>
						Password
					</label>
					<div style={{ position: "relative" }}>
						<input
							className="w-full px-3 py-2 border rounded"
							type={passwordVissibility ? "text" : "password"}
							id="password"
							required
							name="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<span
							style={{
								position: "absolute",
								right: 15,
								top: 10,
								height: 25,
								width: 25,
								padding: 0,
								cursor: "pointer",
							}}
							onClick={managePasswordVisibility}
						>
							{!passwordVissibility ? (
								<IoMdEyeOff name="ios-eye" size={24} color="black" />
							) : (
								<IoMdEye name="ios-eye-off" size={24} color="black" />
							)}
						</span>
					</div>
				</div>

				<div className="mt-10">
					<BlueBotton
						onClick={Login}
						label={loading ? "Loading ..." : "Login"}
					/>
				</div>
				<div className="mt-5">
					<span
						className="text-[#667085] text-[
12px] font-normal"
					>
						Forgot Password?
					</span>
				</div>
			</div>
		</div>
	);
}

export default Login;
