/** @format */

import React, { Fragment, useState, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import noti from "../../assets/not.svg";
import axios from "axios";
import dropdownIco from "../../assets/keyboard_arrow_down.svg";
import home from "../../assets/home.svg";
import { getUser } from "../../services/requests";
const navigation = [
	{ name: "Dashboard", href: "#", current: true },
	{ name: "Team", href: "#", current: false },
	{ name: "Projects", href: "#", current: false },
	{ name: "Calendar", href: "#", current: false },
];

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Header({ label }) {
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState([]);

	const [userData, setUserData] = useState(null);
	const getEntries = async () => {
		try {
			const results = await sessionStorage.getItem("mydata");
			const parsedData = JSON.parse(results);

			// Assuming `mydata` contains user data, update the state with user data
			setUserData(parsedData);
		} catch (error) {
			console.error("Error retrieving and parsing data:", error);
			// Handle the error as needed, e.g., show an error message to the user
		}
	};

	useEffect(() => {
		getEntries();
	}, []);
	console.log(userData, "user header");
	return (
		<Disclosure as="nav" className="bg-[#FFFFFF] w-full h-[80px]">
			{({ open }) => (
				<>
					<div className="content-center grid h-24 min-h-full mx-auto px-2 sm:px-6 lg:px-10 ">
						<div className="relative flex h-16 items-center justify-between">
							<div className=" inset-y-0 left-0 flex items-center sm:hidden">
								{/* Mobile menu button*/}
								<Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
									<span className="sr-only">Open main menu</span>
									{open ? (
										<XMarkIcon className="block h-6 w-6" aria-hidden="true" />
									) : (
										<img className="block h-4 w-4 mr-3" src={home} alt="hek0" />
									)}
								</Disclosure.Button>
							</div>
							<div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
								<div className="flex flex-shrink-0 items-center">
									<span
										className="text-[#334155] font-
Mulish text-2xl"
									>
										{label}
									</span>
								</div>
							</div>
							<div className=" inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 gap-2">
								<p className="p-1 px-3 rounded-lg text-[#64748B] bg-[#CAEFFB] text-sm">
									{userData?.email}
								</p>
								<span className="sr-only">View notifications</span>
								<img className="mr-4" src={noti} alt="My SVG" />

								{/* Profile dropdown */}

								<div className="flex items-center gap-1">
									<p className="text-[#64748B] text-sm">{userData?.username}</p>
									<img src={dropdownIco} alt="user" />
								</div>
								<Transition
									as={Fragment}
									enter="transition ease-out duration-100"
									enterFrom="transform opacity-0 scale-95"
									enterTo="transform opacity-100 scale-100"
									leave="transition ease-in duration-75"
									leaveFrom="transform opacity-100 scale-100"
									leaveTo="transform opacity-0 scale-95"
								>
									<Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
										<Menu.Item>
											{({ active }) => (
												<a
													href="/"
													className={classNames(
														active ? "bg-gray-100" : "",
														"block px-4 py-2 text-sm text-gray-700"
													)}
												>
													Your Profile
												</a>
											)}
										</Menu.Item>
										<Menu.Item>
											{({ active }) => (
												<a
													href="/"
													className={classNames(
														active ? "bg-gray-100" : "",
														"block px-4 py-2 text-sm text-gray-700"
													)}
												>
													Settings
												</a>
											)}
										</Menu.Item>
										<Menu.Item>
											{({ active }) => (
												<a
													href="/"
													className={classNames(
														active ? "bg-gray-100" : "",
														"block px-4 py-2 text-sm text-gray-700"
													)}
												>
													Sign out
												</a>
											)}
										</Menu.Item>
									</Menu.Items>
								</Transition>
							</div>
						</div>
					</div>

					<Disclosure.Panel className="sm:hidden">
						<div className="space-y-1 px-2 pb-3 pt-2">
							{navigation.map((item) => (
								<Disclosure.Button
									key={item.name}
									as="a"
									href={item.href}
									className={classNames(
										item.current
											? "bg-gray-900 text-white"
											: "text-gray-300 hover:bg-gray-700 hover:text-white",
										"block rounded-md px-3 py-2 text-base font-medium"
									)}
									aria-current={item.current ? "page" : undefined}
								>
									{item.name}
								</Disclosure.Button>
							))}
						</div>
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	);
}
