/** @format */

import axios from "axios";
let token = "";
const api = axios.create({
	baseURL: "https://flashserve-api-761656c703cc.herokuapp.com",
	headers: {
		//prettier-ignore
		// Authorization: "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2RldmFwaS5mdXNlZXJwLmNvbS8iLCJzdWIiOiJ1c2VyXzZmNzYyOTRhZTVlYzRmMjJiNjMxZWYwMzU2ZGM2MDdjIiwiZnVsbF9uYW1lIjoiQWRld2FsZSBBemVleiIsImVtYWlsIjoiYWRld2FsZUBrdWRhdmVzdC5jb20iLCJwaG9uZV9udW1iZXIiOiIwOTAyNzg5NDg2MCIsInRlbmFudF9pZCI6InRlbmFudF9mNjljZThhNGM3OWQ0MjVhYjY4ZDU2ZGY3MTQ4ZDIyMiIsInRlbmFudF90eXBlIjoicGFydG5lcnMiLCJ0ZW5hbnRfbmFtZSI6Ikt1ZGFWZXN0Iiwicm9sZSI6IkZ1c2VFUlBQYXJ0bmVycyIsImFkZHJlc3MiOiJTYW4gZnJhbmNpc2NvIGJheSBhcmVhIiwiaWF0IjoxNjk0NjEyMTcxLCJhdWQiOiJodHRwczovL2RldmFwaS5mdXNlZXJwLmNvbS8iLCJleHAiOjE2OTUyMTY5NzF9.kLRTFe9MN8A5BHYNUu6342k7FlDWSZy3j_XPDzGrzdeZ10twWpQjd3T-wJXt_n0E6Q0WreQlreXEUHtWf-BOfDXemMEkEhyUB7tI78AxZbKIX3Tb4wcLjLuUpFEWcusLmR2l8eNcUeEtUo64w5y8iPJDSNXWjkjDLYNV300FKgvFVNsdi2OOJJbRL3sxBhx93GLJzhc1a-LkVaIMTfsj7Dwm4vYv3KQgfF0YHCfYc9lwbFqSxJdXhmQT-y8NeNFmMb8-Daa9Y8AksLzqX2mVOlnw2gdhsmCPW9vlkkZKiFzuFYWu7H0OVpe1Y2vtbJaaGiihLd4hTWgmtsmWukPbVrzSv74NdNAZ5fmMCvmcqVAbaAklqgBod1I7GMwTjk4QR4cjTHwSCvsONyUIGnprmazKqdGsunOLTDwQ-xO7bmtBMOvvu-Baw1afODUSknKgChATR1lhQDTjGFtf3Kq5VNJrgLZi6P-2I2ylT5H6sMxjwo6cs4SN6mYHdPD19F2fWyxf8ERe9POXhqIcKdoEsluBECkHWXHwCroh9tAvdK8fKohPTbIFTurPrBgh1HqdccdtVedc0ovZJy2HStvuhlWZ5UAisxH1IpaPY0YXSMqtM4lLBjz4-luXUj0C_Izzq1rOAYRF5mvEQ9Djp1i0g3E1aUEBXDoplbwTR3qRTsI",
		"Content-Type": "application/json",
	},
});

export default api;
