import React from "react";
import cans from "../assets/cans.png";
import done from "../assets/done.png";

function Modal({ isOpen, onClose, children, title, content }) {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-start justify-end z-50 mt-10 mr-10">
            <div className="modal-overlay absolute inset-0 "></div>

            <div className="modal-content bg-white rounded-lg shadow-md relative p-5 flex border-l-[15px] border-[#10B981]">
                <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                    onClick={onClose}
                >
                    <img width="15" height="15" src={cans} alt="rightarr" />
                </button>

                <div className="pr-6">
                    <img width="35" height="35" src={done} alt="rightarr" />
                </div>
                <div>
                    {title && (
                        <p className="text-[18px] font-semibold text-[#475569] leading-5 mb-3">
                            {title}
                        </p>
                    )}
                    <div>
                        <span className="text-[14px] font-normal text-[#475569] leading-5">
                            {content}
                        </span>
                    </div>
                </div>

                {children}
            </div>
        </div>
    );
}

export default Modal;
