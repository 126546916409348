/** @format */

import React, { useState, useEffect } from "react";

import "../atoms/purchase.css";
import Layout from "../atoms/layout";
import Card from "../atoms/Card";
import icon2 from "../assets/icontwo.png";
import Suspension from "../atoms/layout/Suspension";
import Modal from "../atoms/Modal";
import BlueBotton from "../atoms/BlueBotton";
import LineButton from "../atoms/LineButton";
import ViewDocument from "../atoms/ViewDocument";
import doc from "../assets/down.svg";
import Null from "../atoms/Null";
import axios from "axios";
import Loader from "../atoms/Loader";
import RedButton from "../atoms/RedButton";
import UnsuspendAlert from "../atoms/UnsuspendAlert";
import Alert from "../atoms/Alert";
import NoSuspension from "../atoms/NoSuspension";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
const RidderDetails = () => {
	const [modal, setModal] = useState(false);
	const [unModal, setUnModal] = useState(false);
	const [suspend, setSuspend] = useState(false);
	const [unSuspend, setUnSuspend] = useState(false);
	const [sucess, setSucess] = useState(false);
	// const [aprove, setAprove] = useState(false);
	const [unSucess, setUnSucess] = useState(false);
	const [id, setId] = useState("");
	const [userData, setUserData] = useState("");
	const [loading, setLoading] = useState(false);
	const [loadingPage, setLoadingPage] = useState(true);
	const [search, setSearch] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const closeSuspend = () => {
		setSuspend(false);
	};

	const handleSearch = (event, dataArray, propertyToSearch) => {
		const searchText = event.target.value.toLowerCase(); // Convert searchText to lowercase for case-insensitive search
		setSearch(searchText);

		const filteredData = dataArray?.filter((item) => {
			return (
				item.vendorName.toLowerCase().includes(searchText) ||
				item.customerName.toLowerCase().includes(searchText)
				// Add more conditions for other properties you want to search
			);
		});

		setSearchResults(filteredData);
	};

	const openSucess = () => {
		setSucess(true);
		setSuspend(false);
	};
	const closeSucess = () => {
		setSucess(false);
	};
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModals = () => {
		setIsModalOpen(true);
	};

	const closeModals = () => {
		setIsModalOpen(false);
	};
	const openModal = () => {
		setModal(true);
	};
	const openUnModal = () => {
		setUnModal(true);
	};
	const closeModal = () => {
		setModal(false);
	};
	const closeUnModal = () => {
		setUnModal(false);
	};
	const openSuspend = () => {
		setSuspend(true);
		setModal(false);
	};
	const openUnSuspend = () => {
		setUnSuspend(true);
		setUnModal(false);
	};

	const closeUnSuspend = () => {
		setUnSuspend(false);
	};

	const openUnSucess = () => {
		setUnSucess(true);
		setUnSuspend(false);
	};

	// ...
	useEffect(() => {
		// Retrieve stored checkedRowsData from sessionStorage
		const savedCheckedRowsData = JSON.parse(
			sessionStorage.getItem("selectedIDs")
		);
		if (savedCheckedRowsData) {
			setId(savedCheckedRowsData);
		}
	}, []);

	const RiderDetails = async () => {
		try {
			const result = sessionStorage.getItem("myToken");

			const newToken = JSON.parse(result);
			const API_URL = `https://flashserve-api-761656c703cc.herokuapp.com/api/v1/admin/user/${id[0]}`;
			const config = {
				headers: {
					Authorization: `Bearer ${newToken}`,
					"Content-Type": "application/json",
				},
			};
			const response = await axios.get(API_URL, config);
			console.log(response?.data?.data, "hello setups");
			setUserData(response?.data?.data);
			setLoadingPage(false);
		} catch (error) {
			setLoadingPage(false);
			console.error(error, "errorrr"); // Use console.error for error messages
		}
	};
	const SuspendUser = async () => {
		setLoading(true);
		try {
			const result = sessionStorage.getItem("myToken");

			const newToken = JSON.parse(result);
			const API_URL = `https://flashserve-api-761656c703cc.herokuapp.com/api/v1/admin/user/${id[0]}`;
			const config = {
				headers: {
					Authorization: `Bearer ${newToken}`,
					"Content-Type": "application/json",
				},
			};
			const response = await axios.delete(API_URL, config);
			console.log(response?.data?.data, "hello setups");
			setUserData(response?.data?.data);
			openSucess();
			setLoading(false);
		} catch (error) {
			console.error(error, "errorrr"); // Use console.error for error messages
			setLoading(false);
		}
	};
	const UnSuspendUser = async () => {
		setLoading(true);
		try {
			const result = sessionStorage.getItem("myToken");

			const newToken = JSON.parse(result);
			const API_URL = `https://flashserve-api-761656c703cc.herokuapp.com/api/v1/admin/user/${id[0]}`;
			const config = {
				headers: {
					Authorization: `Bearer ${newToken}`,
					"Content-Type": "application/json",
				},
			};
			const response = await axios.patch(API_URL, {}, config);
			console.log(response?.data?.data, "hello setups");
			setUserData(response?.data?.data);
			openUnSucess();
			setLoading(false);
		} catch (error) {
			console.error(error, "errorrr"); // Use console.error for error messages
			setLoading(false);
		}
	};
	const approveRidder = async () => {
		setLoading(true);
		try {
			const result = sessionStorage.getItem("myToken");

			const newToken = JSON.parse(result);
			const API_URL = `https://flashserve-api-761656c703cc.herokuapp.com/api/v1/admin/user/${id[0]}`;
			const config = {
				headers: {
					Authorization: `Bearer ${newToken}`,
					"Content-Type": "application/json",
				},
			};
			const response = await axios.patch(API_URL, {}, config);
			openSucess();
			setLoading(false);
		} catch (error) {
			console.error(error, "errorrr"); // Use console.error for error messages
			setLoading(false);
		}
	};
	useEffect(() => {
		RiderDetails();
	}, [id]);
	const itemsPerPage = 4; // Change this value based on your requirement
	const [currentPage, setCurrentPage] = useState(0);

	const paginatedData = userData?.trips
		?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
		.map((item) => {
			console.log(item, "datasss");
			return {
				vendorDetails: item,
			};
		});
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const totalPages = Math.ceil(userData?.trips?.length / itemsPerPage);
	console.log(userData, "use");
	return (
		<div>
			{loadingPage ? (
				<Loader />
			) : (
				<Layout>
					<div className="flex justify-end mt-5 mx-10 gap-4">
						<div className="flex gap-4">
							<LineButton
								label={"View uploaded documents"}
								onClick={() => openModals()}
							/>
							<BlueBotton
								label={"Approve rider"}
								onClick={() => approveRidder()}
							/>
							<BlueBotton label={"UnSuspend Vendor"} onClick={openUnModal} />
							<RedButton label={"Suspend vendor"} onClick={openModal} />
						</div>
					</div>
					<Alert
						onClick={openSuspend}
						title={"Suspend Vendor"}
						content={
							<div className="text-[#64748B] font-semibold text-[16px] leading-6">
								Are you sure you want to suspend this vendor
							</div>
						}
						isOpen={modal}
						onClose={closeModal}
					/>
					<UnsuspendAlert
						onClick={openUnSuspend}
						title={"Unsuspend Vendor"}
						content={
							<div className="text-[#64748B] font-semibold text-[16px] leading-6">
								Are you sure you want to unSuspend this vendor
							</div>
						}
						isOpen={unModal}
						onClose={closeUnModal}
					/>
					<Suspension
						label={loading ? "Loading....." : "SUSPEND"}
						title={"Suspend Vendor"}
						isOpen={suspend}
						onClick={SuspendUser}
						onClose={closeSuspend}
					/>
					<NoSuspension
						label={loading ? "Loading....." : "UNSUSPEND"}
						title={"UnSuspend Vendor"}
						isOpen={unSuspend}
						onClick={UnSuspendUser}
						onClose={closeUnSuspend}
					/>
					<Modal
						isOpen={sucess}
						onClose={closeSucess}
						title={"Ridder successFully approved"}
					/>
					<ViewDocument
						isOpen={isModalOpen}
						onClose={closeModals}
						card1Content={
							<div className="flex">
								<a
									href={userData?.riderDetails?.vehicleLicense}
									download="filename"
								>
									<img
										src={doc}
										alt="hello"
										style={{ width: "100%", height: "100%" }}
									/>
								</a>
							</div>
						}
						card2Content={
							<div className="flex">
								<a
									href={userData?.riderDetails?.vehicleDocument}
									download="filename"
								>
									<img
										src={doc}
										alt="hello"
										style={{ width: "100%", height: "100%" }}
									/>
								</a>
							</div>
						}
						card3Content={
							<div className="flex">
								<a
									href={userData?.riderDetails?.vehiclePhotos}
									download="filename"
								>
									<img
										src={doc}
										alt="hello"
										style={{ width: "100%", height: "100%" }}
									/>
								</a>
							</div>
						}
						card1Label="Vehicle License.pdf"
						card2Label="Vehicle Documents.pdf"
						card3Label="Vehicle Photos.pdf"
					/>

					<div className="mx-10    mt-10 mb-10 flex gap-8">
						<Card
							imageSource={icon2}
							description="Phone Number"
							description3="Email"
							iconClass="fas fa-info-circle" // Replace with n
							iconLabel="Information"
							descriptionSub3={userData?.userCollection?.email}
							amount={userData?.userCollection?.phone}
						/>
						<Card
							imageSource={icon2}
							description="Trips"
							description2="Active Trips"
							iconClass="fas fa-info-circle"
							iconLabel="Information"
							numbers={"0"}
							amount={userData?.trips?.length}
						/>
					</div>

					<div className="rounded-lg shadow-lg bg-white my-20 mx-10">
						<div className="px-10 py-5">
							<div className=" bg-white   px-10 py-5 w-[100%] justify-between ">
								<div className=" bg-white w-full justify-between flex h-full">
									<div className="flex w-3/4">
										<form>
											<label
												for="default-search"
												class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white w-full"
											>
												Search
											</label>
											<div class="relative w-96">
												<div class="absolute w-full inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
													<svg
														aria-hidden="true"
														class="w-5 h-5 text-gray-500 dark:text-gray-400"
														fill="none"
														stroke="currentColor"
														viewBox="0 0 24 24"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
															d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
														></path>
													</svg>
												</div>
												<input
													type="search"
													class="block w-full p-2 pl-10  text-sm text-[#000000] rounded   dark:focus:ring-blue-500 border-2 border-[#CBD5E1] "
													placeholder="Search for employee or job title"
													value={search}
													onChange={(event) =>
														handleSearch(
															event,
															userData?.trips,
															"vendorName															",
															"customerName"
														)
													}
												/>
											</div>
										</form>
									</div>
									<div className="flex w-1/4">
										<div className="relative text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
											<select className="absolute inset-y-0 left-0 pl-4 pr-2 text-sm text-gray-900 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-[#CBD5E1] ">
												<option value="">Select Action</option>
												<option value="edit">Edit</option>
												<option value="delete">Delete</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="bg-gray-200 p-2 py-8 ">
							<div className="grid grid-cols-6 p-2py-8 text-[#475569] font-semibold ml-10">
								<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
									Customer Name
								</div>
								<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
									Cusomer Address
								</div>
								<div className="text-center text-xs font-semibold text-[#475569] uppercase tracking-wider">
									Order ID
								</div>
								<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
									Vendor Name
								</div>
								<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
									Vendor Address
								</div>

								<div className="text-centre text-xs font-semibold text-[#475569] uppercase tracking-wider">
									Status
								</div>
							</div>
						</div>
						<div>
							{search === "" ? (
								userData?.trips?.length === 0 ? (
									<Null />
								) : (
									<div>
										{paginatedData?.map((row, index) => {
											return (
												<div
													className={`grid grid-cols-6 py-4 border-b ml-10`}
													key={index}
												>
													<div className=" gap-2 flex text-left text-sm">
														<img
															className="w-9 h-9 rounded-md"
															src={row.vendorDetails.customer_details.photo}
															alt={"imageAlt"}
														/>
														<button className="focus:outline-none text-[#2563EB] font-medium">
															{row.vendorDetails.customerName}
														</button>
													</div>
													<div className="text-left text-sm">
														{row.vendorDetails.customerAddress}
													</div>
													<div className="text-center text-lg">
														{row.vendorDetails.orderId}
													</div>
													<div className="text-left text-sm">
														{row.vendorDetails.vendorName}
													</div>
													<div className=" gap-2 flex text-left text-sm">
														<img
															className="w-9 h-9 rounded-md"
															src={
																row.vendorDetails.vendor_details.businessLogo
															}
															alt={"imageAlt"}
														/>
														{row.vendorDetails.vendorAddress}
													</div>

													<div className="text-[#007BE0]   text-[12px] font-semibold leading-7 mx-10">
														{row.vendorDetails.trip_status}
													</div>
												</div>
											);
										})}
										<div className="mb-5">
											<button
												onClick={() => handlePageChange(currentPage - 1)}
												disabled={currentPage === 0}
											>
												<FontAwesomeIcon icon={faChevronLeft} /> Previous
											</button>

											<span>{`Page ${currentPage + 1} of ${totalPages}`}</span>

											<button
												onClick={() => handlePageChange(currentPage + 1)}
												disabled={currentPage === totalPages - 1}
											>
												Next <FontAwesomeIcon icon={faChevronRight} />
											</button>
										</div>
									</div>
								)
							) : searchResults?.length === 0 ? (
								<Null />
							) : (
								<div>
									{searchResults?.map((row, index) => {
										return (
											<div
												className={`grid grid-cols-7 py-4 border-b`}
												key={index}
											>
												<div className="cell ml-3">
													<input type="checkbox" />
												</div>
												<div className=" gap-2 flex text-left text-sm">
													<img
														className="w-9 h-9 rounded-md"
														src={row.customer_details.photo}
														alt={"imageAlt"}
													/>
													<button className="focus:outline-none text-[#2563EB] font-medium">
														{row.customerName}
													</button>
												</div>
												<div className="text-left text-sm">
													{row.customerAddress}
												</div>
												<div className="text-center text-lg">{row.orderId}</div>
												<div className=" gap-2 flex text-left text-sm">
													<img
														className="w-9 h-9 rounded-md"
														src={
															row.vendor_details.businessLogo ||
															row.vendor_details.businessPhoto
														}
														alt={"imageAlt"}
													/>
													{row.vendorAddress}
												</div>
												<div className="text-left text-sm">
													{row.vendorAddress}
												</div>

												<div className="text-[#007BE0]   text-[12px] font-semibold leading-7 mx-10">
													{row.trip_status}
												</div>
											</div>
										);
									})}
								</div>
							)}
						</div>
						<div className="p-5 flex items-center justify-between mt-5"></div>
					</div>
				</Layout>
			)}
		</div>
	);
};

export default RidderDetails;
