/** @format */

import React from "react";

function BlueBotton({ onClick, label, ...props }) {
	return (
		<div {...props} className="">
      <button
        onClick={onClick}
        className="text-lg sm:text-xl md:text-2xl lg:text-xl xl:text-xl bg-[#0468BF] font-semibold text-[#FFFFFF] py-2 px-4 sm:px-2 md:px-4 lg:px-6 xl:px-8 rounded-md w-full sm:w-[200px]"
      >
        {label}
      </button>
    </div>
	);
}

export default BlueBotton;
