/** @format */

import React from "react";

const BlueCard = ({
	imageSource,
	imageAlt,
	title,
	description,
	description2,
	description3,
	iconClass,
	iconLabel,
	descriptionSub3,
	amount,
	numbers,
    onClick, // Add onClick prop
}) => {
	return (
		<div  onClick={onClick}  className=" rounded-md  flex-1 overflow-hidden shadow-lg bg-[#97DAF8] h-48">
			<img className="my-3 mx-3" src={imageSource} alt={imageAlt} />
			<div className="px-6 py-4">
				<div className="flex justify-between">
					<p className="text-[#4F4F4F] text-[14px]">
						{description}
						<p className="text-[#333333] text-[16px] font-semibold leading-7">
							{amount}
						</p>
					</p>
					<p className="text-[#333333] text-[20px] font-semibold leading-7">
						{title}
					</p>
					<p className="text-[#4F4F4F] text-[14px]">
						{description2}
						<p className="text-[#333333] text-[14px] font-semibold leading-7">
							{numbers}
						</p>
					</p>
					<p className="text-[#4F4F4F] text-[14px]">
						{description3}
						<p className="text-[#333333] text-[20px] font-semibold leading-7">
						
							{descriptionSub3}
						</p>
					</p>
				</div>
			</div>
			<div className="px-6 py-4">
				<i className={`${iconClass} text-blue-500`}></i>
				{/* <span className="text-gray-600 ml-2">{iconLabel}</span> */}
			</div>
		</div>
	);
};

export default BlueCard;
