/** @format */

import api from "./api"

export function uploadImage(data) {
	return api.post(`/imageupload/`, data)
}


// ***** DEDUCTION ENDPOINT STARTS ******







// End point to add vendor Address
export function vendorAddress(data, token) {
	const response = api.post("/vendor/onboard/addAddress", data, {
		headers: {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		},
	})
	return response
}

// End point to onboard vendor
export function onboardVendor(data, token) {
	const response = api.post("/vendor/onboard/onboard", data, {
		headers: {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		},
	})
	return response
}

// End point to Add Product (vendor)
export function addProduct(data, token) {
	const response = api.post("/vendor/product/add", data, {
		headers: {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		},
	})
	return response
}

// End point to Get categories (vendor)
export function getCategories(token) {
	const response = api.get("/vendor/onboard/services", {
		headers: {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		},
	})
	return response
}

// End point to Get Product (vendor)
export function getProduct(token) {
	const response = api.get("/vendor/product/products", {
		headers: {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		},
	})
	return response
}

// End point to Get Product by ID (vendor)
export function getProductById(token, id) {
	const response = api.get(`/vendor/product/product/${id}`, {
		headers: {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		},
	})
	return response
}

// End point to delete Product (vendor)

// End point to edit Product (vendor)
export function getCategory(token) {
	const response = api.get(`/api/v1/admin/categories`, {
		headers: {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		},
	})
	return response
}

// End point to Get (vendor) details
export function getProducts(token) {
	const response = api.get(`/api/v1/admin/products`, {
		headers: {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		},
	})
	return response
}


///get user//
export function getUser(token) {
	const response = api.get(`/api/v1/admin/users/?user_type=customer`, {
		headers: {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		},
	})
	return response
}
///get vendor//
export function getVendor(token) {
	const response = api.get(`/api/v1/admin/users/?user_type=vendor`, {
		headers: {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		},
	})
	return response
}

///get rider///
export function getRider(token) {
	const response = api.get(`/api/v1/admin/users/?user_type=rider`, {
		headers: {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		},
	})
	return response
}
export function getAllProducts(token) {
	const response = api.get(`/api/v1/admin/products`, {
		headers: {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		},
	})
	return response
}

// Get list of Order details
export function getOrder(token) {
	const response = api.get(`/api/v1/admin/orders/?status=`, {
		headers: {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		},
	})
	return response
}

// Accept Orders
export function acceptOrder(token, id) {
	const response = api.get(`/vendor/order/accept/${id}`, {
		headers: {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		},
	})
	return response
}

// decline Orders
export function declineOrder(token, id) {
	const response = api.get(`/vendor/order/decline/${id}`, {
		headers: {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		},
	})
	return response
}



// Assign order to Rider

// export async function CompleteSetUp(id, data, token) {
// 	try {
// 		const response = await api.patch(`/payroll/${id}/complete-setup`, data, {
// 			data: data,
// 			headers: {
// 				Authorization: `Bearer ${token}`,
// 				"Content-Type": "application/json",
// 			},
// 		})
// 		return response
// 	} catch (error) {
// 		throw error
// 	}
// }
// confirm banks account number


