/** @format */

import React from "react";

const RecentOrder = ({
	imageAlt,
	createdAt,
	completed,
	data,
	order_status

}) => {
	console.log(data.createdAt, "childsd");
	const formatDateAndTime = (dateTimeString) => {
		const dateTime = new Date(dateTimeString);

		// Separate date and time
		const date = dateTime.toLocaleDateString(undefined, {
			year: "numeric",
			month: "long",
			day: "numeric",
		});
		const time = dateTime.toLocaleTimeString(undefined, {
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
		});

		return { date, time };
	};
	return (
		<div>
			<div className="border-b border-gray-400 w-full my-4"></div>
			<div className="px-2 py-2">
				<div className="flex justify-between items-center">
					<div className="flex items-center">
						<img
							src={data?.productDetails?.photos[0]}
							alt={imageAlt}
							className="h-10 w-10 rounded-md mr-4"
						/>
						<div>
							<p className="text-[#333333] text-12px font-normal">
								{data?.productDetails?.name}
							</p>
							<p className="text-[#171520] text-14px font-semibold">
								₦ {data?.productDetails?.price}
							</p>
						</div>
					</div>
					<div>
						<p className="text-[#333333] text-12px font-normal leading-7">
							{formatDateAndTime(data.createdAt).date}
						</p>
						<p
							className={`text-${
								data?.order_status
								=== "pending" ? "#36A825" : "red"
							} text-12px font-semibold leading-7 ${
								completed ? "bg-[#E4FAD3]" : "red"
							}`}
						>
							{data?.order_status}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RecentOrder;
