import React from "react";
import { useLocation } from "react-router-dom";

function SideBarItem({ title, icon, dropdown }) {
  const location = useLocation();
  const isActive = location.pathname === "/" + title.toLowerCase(); // Adjust this to match your route structure

  return (
    <div
      className={`flex gap-2 px-8 h-[56px] items-center justify-between cursor-pointer ${
        isActive ? "text-[#2563EB]" : "text-[#475569]"
      }`}
    >
      <div className="flex gap-2 items-center w-full">
        <img className="h-6 w-6" alt="side-ico" src={icon} />
        <p className="font-normal text-base">{title}</p>
      </div>
    </div>
  );
}

export default SideBarItem;
