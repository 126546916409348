/** @format */

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../Login/Login";
import Dashboard from "../dashboard/Dashboard";
import Orders from "../atoms/Orders";
import Users from "../user/Users";
import UserDetail from "../user/UserDetail";
import Vendor from "../vendor/Vendor";
import VendorDetails from "../vendor/VendorDetails";
import Ridder from "../ridder/Ridder";
import RidderDetails from "../ridder/RidderDetails";
import RidderRequest from "../ridder/RidderRequest";
import OrderSummary from "../orderSummmary/OrderSummary";
import OrderDetaills from "../orderSummmary/OrderDetaills";
import ProductPage from "../productPage/ProductPage";
import ProductDetails from "../productPage/ProductsDetails";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Login />} />
				<Route path="/dashboard" element={<Dashboard />} />
				<Route path="/dashboardorder" element={<Orders />} />
				<Route path="/user" element={<Users />} />
				<Route path="/userdetails" element={<UserDetail />} />
				<Route path="/vendor" element={<Vendor />} />
				<Route path="/vendordetails" element={<VendorDetails />} />
				<Route path="/orders" element={<OrderSummary />} />
				<Route path="/ridder" element={<Ridder />} />
				<Route path="/ridderdetails" element={<RidderDetails />} />
				<Route path="/ridderrequest" element={<RidderRequest />} />
				<Route path="/orderDetails" element={<OrderDetaills />} />
				<Route path="/allproducts" element={<ProductPage />} />
				<Route path="/productsDetails" element={<ProductDetails/>} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
