/** @format */

import React from "react";

function LineButton({label,...props}) {
	return (
		<div {...props} className="">
			<button className="text-sm bg-[#EFF6FF] font-bold text-[#0468BF] py-2 px-2 rounded-md mr-9 border border-[#0468BF]">
				{label}
			</button>
		</div>
	);
}

export default LineButton;
