/** @format */

import React from "react";
import BlueBotton from "./BlueBotton";
import nulls from "../assets/null.svg";
function Null() {
	return (
		<div className="grid justify-items-center">
			<img src={nulls} alt="hello" />
			<div className="m-5">
			
				<BlueBotton label="EMPTY" />
			</div>
		</div>
	);
}

export default Null;
