/** @format */

import React, { useState } from "react";

const MultiHeaderCard = ({ headers, userData  }) => {
	const [selectedHeader, setSelectedHeader] = useState(headers[0]);

	const formatDateAndTime = (dateTimeString) => {
		const dateTime = new Date(dateTimeString);

		// Separate date and time
		const date = dateTime.toLocaleDateString(undefined, {
			year: "numeric",
			month: "long",
			day: "numeric",
		});
		const time = dateTime.toLocaleTimeString(undefined, {
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
		});

		return { date, time };
	};
	
	
	return (
		<div className="bg-white flex-1  p-4 rounded-lg shadow-md">
			<div className="flex justify-between">
				{headers.map((header) => (
					<button
						key={header.main}
						onClick={() => setSelectedHeader(header)}
						className={`px-4 py-2 rounded-lg ${
							selectedHeader === header
								? "bg-blue-500 text-white"
								: "bg-gray-200"
						}`}
					>
						{header.main}
					</button>
				))}
			</div>
			<div className="container mx-auto p-4">
				<hr className="border border-gray-300 my-4" />
			</div>
			<div className="mt-4 ">
				<h2 className="text-[16px] text-left font-semibold justify-start">{selectedHeader.main}</h2>
				{selectedHeader.subHeaders.map((subHeader) => {
					console.log(subHeader.title, "tit");
					return (
						<div key={subHeader.title} className="mt-2 w-full">
							{subHeader?.title === "Subheader 2" ? (
								<div>
									<hr className="border border-gray-300 my-4" />
								
									<div className="grid grid-cols-3 p-2 py-8 text-[#475569] font-semibold">
		
										<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
										Date
										</div>
										<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
										Status
										</div>
										<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
										User
										</div>
									</div>
									
											<div
												className={`grid grid-cols-3 py-4 border-b`}
											
											>
												<div className="text-left text-sm">
													<button className="focus:outline-none text-[#333333] font-medium">
													{formatDateAndTime(userData?.order_details?.date
).date} {formatDateAndTime(userData?.order_details?.date
	).time}
													</button>
												</div>
												<div className="text-left text-sm">{userData?.order_details?.orderstatus}</div>
										
												<div className="text-left text-sm">
												{userData?.customer_details?.username
}
												</div>
												

											

												
											</div>
									
								</div>
							) : null}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default MultiHeaderCard;
