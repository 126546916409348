/** @format */

import React, { useState, useEffect } from "react";

import "../atoms/purchase.css";
import Layout from "../atoms/layout";
import Card from "../atoms/Card";
import doc from "../assets/down.svg";
import icon2 from "../assets/icontwo.png";
import RedButton from "../atoms/RedButton";
import f1 from "../assets/f1.svg";
import Alert from "../atoms/Alert";
import Suspension from "../atoms/layout/Suspension";
import Modal from "../atoms/Modal";
import Card2 from "../atoms/Card2";
import axios from "axios";
import BlueBotton from "../atoms/BlueBotton";
import UnsuspendAlert from "../atoms/UnsuspendAlert";
import NoSuspension from "../atoms/NoSuspension";
import LineButton from "../atoms/LineButton";
import ViewDocument from "../atoms/ViewDocument";
import Loader from "../atoms/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
const VendorDetails = ({ onRowSelection, onEmployeeNameClick }) => {
	const [modal, setModal] = useState(false);
	const [unModal, setUnModal] = useState(false);
	const [suspend, setSuspend] = useState(false);
	const [unSuspend, setUnSuspend] = useState(false);
	const [sucess, setSucess] = useState(false);
	const [aprove, setAprove] = useState(false);
	const [unSucess, setUnSucess] = useState(false);
	const [id, setId] = useState("");
	const [userData, setUserData] = useState("");
	const [loading, setLoading] = useState(false);
	const [pending, setPending] = useState("");
	const [completed, setCompleted] = useState("");
	const [loadingPage, setLoadingPage] = useState(true);
	const [search, setSearch] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [totalSales, setTotalSales] = useState("");
	const handleSearch = (event, dataArray, propertyToSearch) => {
		const searchText = event.target.value;
		setSearch(searchText);

		const filteredData = dataArray?.filter(
			(item) =>
				item[propertyToSearch]?.description
					.toLowerCase()
					.includes(searchText.toLowerCase()) ||
				item[propertyToSearch]?.name
					.toLowerCase()
					.includes(searchText.toLowerCase())
		);

		setSearchResults(filteredData);
	};
	const openModal = () => {
		setModal(true);
	};
	const openUnModal = () => {
		setUnModal(true);
	};
	const closeModal = () => {
		setModal(false);
	};
	const closeUnModal = () => {
		setUnModal(false);
	};
	const openSuspend = () => {
		setSuspend(true);
		setModal(false);
	};
	const openUnSuspend = () => {
		setUnSuspend(true);
		setUnModal(false);
	};
	const closeSuspend = () => {
		setSuspend(false);
	};
	const closeUnSuspend = () => {
		setUnSuspend(false);
	};
	const openSucess = () => {
		setSucess(true);
		setSuspend(false);
	};
	const openApprove = () => {
		setAprove(true);
	};
	const openUnSucess = () => {
		setUnSucess(true);
		setUnSuspend(false);
	};
	const closeSucess = () => {
		setSucess(false);
	};
	const closeAprove = () => {
		setAprove(false);
	};
	const closeUnSucess = () => {
		setUnSucess(false);
	};

	// ...
	useEffect(() => {
		// Retrieve stored checkedRowsData from sessionStorage
		const savedCheckedRowsData = JSON.parse(
			sessionStorage.getItem("selectedIDs")
		);
		if (savedCheckedRowsData) {
			setId(savedCheckedRowsData);
			console.log(
				"Retrieved Checked Rows Data on Page B:",
				savedCheckedRowsData
			);
		}
	}, []);

	const userDetails = async () => {
		try {
			const result = sessionStorage.getItem("myToken");

			const newToken = JSON.parse(result);
			const API_URL = `https://flashserve-api-761656c703cc.herokuapp.com/api/v1/admin/user/${id[0]}`;
			const config = {
				headers: {
					Authorization: `Bearer ${newToken}`,
					"Content-Type": "application/json",
				},
			};
			const response = await axios.get(API_URL, config);
			console.log(response?.data?.data, "hello setups");
			setUserData(response?.data?.data);
			const filteredPending = response?.data?.data?.vendor_orders.filter(
				(item) => item.order_status === "pending"
			);
			const filteredCompleted = response?.data?.data?.vendor_orders.filter(
				(item) => item.order_status === "completed"
			);
			setPending(filteredPending);
			setCompleted(filteredCompleted);
			setLoadingPage(false);
		} catch (error) {
			setLoadingPage(false);
			console.error(error, "errorrr"); // Use console.error for error messages
		}
	};
	const SuspendUser = async () => {
		setLoading(true);
		try {
			const result = sessionStorage.getItem("myToken");

			const newToken = JSON.parse(result);
			const API_URL = `https://flashserve-api-761656c703cc.herokuapp.com/api/v1/admin/user/${id[0]}`;
			const config = {
				headers: {
					Authorization: `Bearer ${newToken}`,
					"Content-Type": "application/json",
				},
			};
			const response = await axios.delete(API_URL, config);
			console.log(response?.data?.data, "hello setups");
			setUserData(response?.data?.data);
			openSucess();
			setLoading(false);
		} catch (error) {
			console.error(error, "errorrr"); // Use console.error for error messages
			setLoading(false);
		}
	};
	const UnSuspendUser = async () => {
		setLoading(true);
		try {
			const result = sessionStorage.getItem("myToken");

			const newToken = JSON.parse(result);
			const API_URL = `https://flashserve-api-761656c703cc.herokuapp.com/api/v1/admin/user/${id[0]}`;
			const config = {
				headers: {
					Authorization: `Bearer ${newToken}`,
					"Content-Type": "application/json",
				},
			};
			const response = await axios.patch(API_URL, {}, config);
			console.log(response?.data?.data, "hello setups");
			setUserData(response?.data?.data);
			openUnSucess();
			setLoading(false);
		} catch (error) {
			console.error(error, "errorrr"); // Use console.error for error messages
			setLoading(false);
		}
	};
	const approveVendor = async () => {
		setLoading(true);
		try {
			const result = sessionStorage.getItem("myToken");

			const newToken = JSON.parse(result);
			const API_URL = `https://flashserve-api-761656c703cc.herokuapp.com/api/v1/admin/user/${id[0]}`;
			const config = {
				headers: {
					Authorization: `Bearer ${newToken}`,
					"Content-Type": "application/json",
				},
			};
			const response = await axios.patch(API_URL, {}, config);
			openApprove();
			setLoading(false);
		} catch (error) {
			console.error(error, "errorrr"); // Use console.error for error messages
			setLoading(false);
		}
	};
	useEffect(() => {
		userDetails();
	}, [id]);
	const formatDateAndTime = (dateTimeString) => {
		const dateTime = new Date(dateTimeString);

		// Separate date and time
		const date = dateTime.toLocaleDateString(undefined, {
			year: "numeric",
			month: "long",
			day: "numeric",
		});
		const time = dateTime.toLocaleTimeString(undefined, {
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
		});

		return { date, time };
	};
	const [isModalOpen, setIsModalOpen] = useState(false);
	const openModals = () => {
		setIsModalOpen(true);
	};
	const closeModals = () => {
		setIsModalOpen(false);
	};
	const itemsPerPage = 3; // Change this value based on your requirement
	const [currentPage, setCurrentPage] = useState(0);

	const paginatedData = userData?.vendor_orders
		?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
		.map((item) => {
			console.log(item, "datasss", userData);
			return {
				productDetails: item,
			};
		});
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const totalPages = Math.ceil(userData?.vendor_orders?.length / itemsPerPage);

	const totalReceivedPrice = userData?.vendor_orders
		?.filter((order) => order.order_status === "received")
		.reduce((sum, order) => sum + parseInt(order.product_details.price), 0);

	const formattedTotalReceivedPrice = `₦ ${totalReceivedPrice}`;
	const pendingOrders = userData?.vendor_orders?.filter(
		(order) => order.status === "pending"
	);
	const lengthOfPendingOrders = pendingOrders?.length;

	console.log('Length of Orders with "pending" status:', lengthOfPendingOrders);

	const completedOrders = userData?.vendor_orders?.filter(
		(order) => order.status === "received"
	);
	const lengthOfCompletedOrders = completedOrders?.length;

	console.log(
		'Length of Orders with "pending" status:',
		lengthOfCompletedOrders
	);
	return (
		<div>
			{loadingPage ? (
				<Loader />
			) : (
				<Layout label={"Customer"}>
					<div className="flex justify-between mt-5 mx-10 gap-4">
						<div className=" flex gap-8">
							<span>
								User Since
								{
									formatDateAndTime(userData?.businessOwnerdetails?.createdAt)
										.date
								}
							</span>
						</div>
						<div className="flex gap-4">
							<LineButton
								label={"View uploaded documents"}
								onClick={() => openModals()}
							/>
							<BlueBotton
								label={"Approve Vendor"}
								onClick={() => approveVendor()}
							/>
							<BlueBotton label={"UnSuspend Vendor"} onClick={openUnModal} />
							<RedButton label={"Suspend vendor"} onClick={openModal} />
						</div>
					</div>
					<Alert
						onClick={openSuspend}
						title={"Suspend Vendor"}
						content={
							<div className="text-[#64748B] font-semibold text-[16px] leading-6">
								Are you sure you want to suspend this vendor
							</div>
						}
						isOpen={modal}
						onClose={closeModal}
					/>
					<UnsuspendAlert
						onClick={openUnSuspend}
						title={"Unsuspend Vendor"}
						content={
							<div className="text-[#64748B] font-semibold text-[16px] leading-6">
								Are you sure you want to unSuspend this vendor
							</div>
						}
						isOpen={unModal}
						onClose={closeUnModal}
					/>
					<Suspension
						label={loading ? "Loading....." : "SUSPEND"}
						title={"Suspend Vendor"}
						isOpen={suspend}
						onClick={SuspendUser}
						onClose={closeSuspend}
					/>
					<NoSuspension
						label={loading ? "Loading....." : "UNSUSPEND"}
						title={"UnSuspend Vendor"}
						isOpen={unSuspend}
						onClick={UnSuspendUser}
						onClose={closeUnSuspend}
					/>
					<Modal
						isOpen={sucess}
						onClose={closeSucess}
						title={"User Account suspended successfully"}
					/>
					<Modal
						isOpen={aprove}
						onClose={closeAprove}
						title={"User Account Approved successfully"}
					/>
					<Modal
						isOpen={unSucess}
						onClose={closeUnSucess}
						title={"You have successfully unsuspended this vendor"}
					/>
					<ViewDocument
						isOpen={isModalOpen}
						onClose={closeModals}
						card1Content={
							<div className="flex">
								<a href={userData?.bussinessDetails?.CAC} download="filename">
									<img
										src={doc}
										alt="hello"
										style={{ width: "100%", height: "100%" }}
									/>
								</a>
							</div>
						}
						card2Content={
							<div className="flex">
								<a href={userData?.bussinessDetails?.nationId} download>
									<img
										src={doc}
										alt="hello"
										style={{ width: "100%", height: "100%" }}
									/>
								</a>
							</div>
						}
						card3Content={
							<div className="flex">
								<a href={userData?.bussinessDetails?.taxClearance} download>
									<img
										src={doc}
										alt="hello"
										style={{ width: "100%", height: "100%" }}
									/>
								</a>
							</div>
						}
						card1Label="Vehicle License.pdf"
						card2Label="Vehicle Documents.pdf"
						card3Label="Vehicle Photos.pdf"
					/>
					<div className="mx-10    mt-10 mb-10 flex gap-8">
						<Card2
							imageSource={icon2}
							description="Phone Number"
							description2="Email"
							iconClass="fas fa-info-circle" // Replace with n
							iconLabel="Information"
							numbers={userData?.businessOwnerdetails?.email}
							amount={userData?.businessOwnerdetails?.phone}
						/>
						<Card
							imageSource={f1}
							description="Business Address"
							iconClass="fas fa-info-circle"
							iconLabel="Information"
							amount={userData?.vendorAddress?.address}
						/>

						<Card2
							imageSource={icon2}
							description="Total Sales"
							iconClass="fas fa-info-circle"
							description2="Total Pending Orders"
							numbers={lengthOfPendingOrders}
							iconLabel="Information"
							amount={formattedTotalReceivedPrice}
							description3="Total Completed Order"
							descriptionSub3={lengthOfCompletedOrders}
						/>
					</div>

					<div className="rounded-lg shadow-lg bg-white my-20 mx-10">
						<div className="px-10 py-5">
							<div className=" bg-white   px-10 py-5 w-[100%] justify-between ">
								<div className=" bg-white w-full justify-between flex h-full">
									<div className="flex w-3/4">
										<form>
											<label
												for="default-search"
												class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white w-full"
											>
												Search
											</label>
											<div class="relative w-96">
												<div class="absolute w-full inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
													<svg
														aria-hidden="true"
														class="w-5 h-5 text-gray-500 dark:text-gray-400"
														fill="none"
														stroke="currentColor"
														viewBox="0 0 24 24"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
															d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
														></path>
													</svg>
												</div>
												<input
													type="search"
													id="default-search"
													class="block w-full p-2 pl-10  text-sm text-[#000000] rounded   dark:focus:ring-blue-500 border-2 border-[#CBD5E1] "
													placeholder="Search"
													value={search}
													onChange={(event) =>
														handleSearch(
															event,
															userData?.vendor_orders,
															"product_details"
														)
													}
												/>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					<div className="bg-gray-200">
					<div className="grid grid-cols-6 p-2 bg-gray-200 py-8 text-[#475569] font-semibold ml-10">
							
							<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
								Order Date
							</div>
							<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
								Quantity
							</div>
							<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
								Price
							</div>
							
							<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
								Phone
							</div>
							<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
								Address
							</div>

							<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider mx-10 ">
								Status
							</div>
						</div>
					</div>
						{search === ""
							? paginatedData?.map((row, index) => {
									return (
										<div
											className={`grid grid-cols-6 py-4 border-b ml-10`}
											key={index}
										>
										
											<div className=" gap-2 flex text-left text-sm">
												<img
													className="w-9 h-9 rounded-md"
													src={row.productDetails.product_details?.photos[0]}
													alt={"imageAlt"}
												/>
												<button
													className="focus:outline-none text-[#2563EB] font-medium"
													onClick={() => {
														sessionStorage.setItem(
															"selectedIDs",
															JSON.stringify([row.id])
														);
													}}
												>
													{row.productDetails.product_details?.name}
												</button>
											</div>
											<div className="text-left text-sm">
												<button
													className="focus:outline-none font-medium"
													onClick={() => {
														onEmployeeNameClick(row.employeeName);
														sessionStorage.setItem(
															"selectedIDs",
															JSON.stringify([row.id])
														);
													}}
												>
													{row.productDetails.quantity}
												</button>
											</div>
											<div className="text-left text-sm">
												₦ {row?.productDetails.product_details?.price}
											</div>
						
											<div className="text-left text-sm">
												{row.productDetails.customer_address?.phone}
											</div>
											<div className="text-left text-sm">
												{row.productDetails.customer_address?.address}
											</div>

											<div className="text-[#007BE0]   text-[12px] font-semibold leading-7 mx-10 flex items-center justify-center rounded-lg">
												{row.productDetails.order_status}
											</div>
										</div>
									);
							  })
							: searchResults.map((row, index) => {
									return (
										<div
											className={`grid grid-cols-8 py-4 border-b`}
											key={index}
										>
											<div className="cell ml-3">
												<input type="checkbox" />
											</div>
											<div className=" gap-2 flex text-left text-sm">
												<img
													className="w-9 h-9 rounded-md"
													src={row.product_details?.photos[0]}
													alt={"imageAlt"}
												/>
												<span className="focus:outline-none text-[#2563EB] font-medium">
													{row.product_details?.name}
												</span>
											</div>
											<div className="text-left text-sm">
												<span className="focus:outline-none font-medium">
													{row.quantity}
												</span>
											</div>
											<div className="text-left text-sm">
												₦ {row?.product_details?.price}
											</div>
											<div className="text-left text-sm">{row.id}</div>
											<div className="text-left text-sm">
												{row.customer_address?.phone}
											</div>
											<div className="text-left text-sm">
												{row.customer_address?.address}
											</div>

											<div className="text-[#007BE0]   text-[12px] font-semibold leading-7 mx-10 flex items-center justify-center rounded-lg">
												{row.order_status}
											</div>
										</div>
									);
							  })}
						<div className="mb-5">
							<button
								onClick={() => handlePageChange(currentPage - 1)}
								disabled={currentPage === 0}
							>
								<FontAwesomeIcon icon={faChevronLeft} /> Previous
							</button>

							<span>{`Page ${currentPage + 1} of ${totalPages}`}</span>

							<button
								onClick={() => handlePageChange(currentPage + 1)}
								disabled={currentPage === totalPages - 1}
							>
								Next <FontAwesomeIcon icon={faChevronRight} />
							</button>
						</div>
						<div className="p-5 flex items-center justify-between mt-5">
							{/* ... (Pagination and Show rows elements remain the same) */}
						</div>
					</div>
				</Layout>
			)}
		</div>
	);
};

export default VendorDetails;
