/** @format */

import React, { useState } from "react";
import cans from "../assets/cans.png";

// Import the icon's CSS
import { useNavigate } from "react-router-dom";
import BlueBotton from "./BlueBotton";

// import RedButton from "./RedButton";
// import BlueBotton from "./BlueBotton";
// import BlueBotton from "./BlueBotton";
// import LineButton from "./LineButton";
function ViewDocument({
	isOpen,
	onClose,
	card1Content,
	card2Content,
	card3Content,
	card1Label,
	card2Label,
	card3Label,
}) {
	const navigate = useNavigate();
	if (!isOpen) return null;

	return (
		<div
			className={`p-5 fixed inset-0 flex items-center justify-center z-50 ${
				isOpen ? "" : "hidden"
			}`}
		>
			<div className="modal-overlay absolute inset-0 bg-black opacity-50"></div>

			<div className="modal-content bg-white rounded-lg shadow-md relative p-10 flex">
				<div>
					<p className=" text-[24px] font-bold text-[#15131B] leading-5 mb-5"></p>
					<p className="mt-5 text-center text-[24px] font-bold text-[#15131B] leading-5 mb-5">
						Uploaded Documents
					</p>
					<p className=" text-center mt-5 mb-3 text-[#15131B] text-[14px] front-semibold ">
						Please carefully review the documents before making any decision, as
						it serves as evidence ,<br />
						of business legality.
					</p>
					<div className="p-4 flex gap-4 w-full mb-20">
						<div className="grid h-40 w-full  p-4 mb-4 border border-[#57575B] rounded-md  ">
					
							<div className="h-40 w-full  p-4 mb-4  rounded-md grid  ">
								{card1Content}
							</div>
							<p className="text-[#15131B] text-center mt-2 text-[14px] font-bold ">
								{card1Label}
							</p>
						</div>
						<div className="grid h-40 w-full  p-4 mb-4 border border-[#57575B] rounded-md  ">
			
							<div className="h-40 w-full  p-4 mb-4  rounded-md grid  ">
								{card2Content}
							</div>
							<p className="text-center text-[#15131B] mt-2 text-[14px] font-bold ">
								{card2Label}
							</p>
						</div>

						<div className="grid h-40 w-full  p-4 mb-4 border border-[#57575B] rounded-md  ">
					
							<div className="h-40 w-full  p-4 mb-4  rounded-md grid  ">
								{card3Content}
							</div>
							<p className="text-[#15131B] text-center mt-2 text-[14px] font-bold ">
								{card3Label}
							</p>
						</div>
					</div>
					<div>
						<BlueBotton onClick={onClose} className="mt-10" label="BACK" />
					</div>
				</div>

				<button
					className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
					onClick={onClose}
				>
					<img width="20" height="20" src={cans} alt="rightarr" />
				</button>
			</div>
		</div>
	);
}

export default ViewDocument;
