

import React, { useState, useEffect } from "react";
import "../atoms/purchase.css";
import Layout from "../atoms/layout";

import RedButton from "../atoms/RedButton";

import Alert from "../atoms/Alert";
import Suspension from "../atoms/layout/Suspension";
import Modal from "../atoms/Modal";
import Card2 from "../atoms/Card2";
import axios from "axios";
import Loader from "../atoms/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
const UserDetail = () => {
	const [selectedRows, setSelectedRows] = useState([]);

	const [modal, setModal] = useState(false);
	const [suspend, setSuspend] = useState(false);
	const [sucess, setSucess] = useState(false);
	const [id, setId] = useState("");
	const [userData, setUserData] = useState("");
	const [loading, setLoading] = useState(true);
	const [search, setSearch] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const handleSearch = (event, dataArray) => {
		const searchText = event.target.value.toLowerCase(); // Convert searchText to lowercase for case-insensitive search
		setSearch(searchText);

		const filteredData = dataArray?.filter((item) => {
			return item?.product_details.name.toLowerCase().includes(searchText);
			// Add more conditions for other properties you want to search
		});

		setSearchResults(filteredData);
	};

	console.log(searchResults, "hi");
	const openModal = () => {
		setModal(true);
	};
	const closeModal = () => {
		setModal(false);
	};
	const openSuspend = () => {
		setSuspend(true);
		setModal(false);
	};
	const closeSuspend = () => {
		setSuspend(false);
	};
	const openSucess = () => {
		setSucess(true);
		setSuspend(false);
		console.log("hrlllo");
	};
	const closeSucess = () => {
		setSucess(false);
	};
	const [token, setToken] = useState("");
	const getEntries = () => {
		const result = sessionStorage.getItem("myToken");

		const newToken = JSON.parse(result);
		setToken(newToken);
		console.error(" parsing JSON:", newToken);
	};
	useEffect(() => {
		getEntries();
	}, [token]);

	// ...
	useEffect(() => {
		// Retrieve stored checkedRowsData from sessionStorage
		const savedCheckedRowsData = JSON.parse(
			sessionStorage.getItem("selectedIDs")
		);
		if (savedCheckedRowsData) {
			setId(savedCheckedRowsData);
			console.log(
				"Retrieved Checked Rows Data on Page B:",
				savedCheckedRowsData
			);
		}
	}, []);
	const userDetails = async () => {
		try {
			const API_URL = `https://flashserve-api-761656c703cc.herokuapp.com/api/v1/admin/user/${id[0]}`;
			const config = {
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			};
			const response = await axios.get(API_URL, config);
			console.log(response?.data?.data, "hello setups");
			setUserData(response?.data?.data);
			setLoading(false);
		} catch (error) {
			console.error(error, "errorrr");
			setLoading(false); // Use console.error for error messages
		}
	};
	const SuspendUser = async () => {
		setLoading(true);
		try {
			const API_URL = `https://flashserve-api-761656c703cc.herokuapp.com/api/v1/admin/user/${id[0]}`;
			const config = {
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			};
			const response = await axios.get(API_URL, config);
			console.log(response?.data?.data, "hello setups");
			setUserData(response?.data?.data);
			openSucess();
			setLoading(false);
		} catch (error) {
			console.error(error, "errorrr"); // Use console.error for error messages
			setLoading(false);
		}
	};
	useEffect(() => {
		userDetails();
	}, [id]);
	const formatDateAndTime = (dateTimeString) => {
		const dateTime = new Date(dateTimeString);

		// Separate date and time
		const date = dateTime.toLocaleDateString(undefined, {
			year: "numeric",
			month: "long",
			day: "numeric",
		});
		const time = dateTime.toLocaleTimeString(undefined, {
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
		});

		return { date, time };
	};
	const itemsPerPage = 4; // Change this value based on your requirement
	const [currentPage, setCurrentPage] = useState(0);

	const paginatedData = userData?.customer_orders
		?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
		.map((item) => {
			console.log(item, "datasss");
			return {
				productDetails: item,
			};
		});
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const totalPages = Math.ceil(
		userData?.customer_orders?.length / itemsPerPage
	);
	return (
		<div>
			{loading ? (
				<Loader />
			) : (
				<Layout label={"Customer"}>
					<div className="flex justify-between mt-5 mx-10 gap-4">
						<div className=" flex gap-8">
							<span>
								User Since
								{formatDateAndTime(userData?.userCollection?.createdAt).date}
							</span>
						</div>
						<div className="flex gap-4">
							{/* <BlueBotton label={"Edit customer"} /> */}
							<RedButton label={"Suspend customer"} onClick={openModal} />
						</div>
					</div>
					<Alert
						onClick={openSuspend}
						label={"Yes, Approve Payroll"}
						title={"Suspend Customer"}
						content={
							<div className="text-[#64748B] font-semibold text-[16px] leading-6">
								Are you sure you want to suspend this customer
							</div>
						}
						isOpen={modal}
						onClose={closeModal}
					/>
					<Suspension
						label={loading ? "Loading....." : "SUSPEND"}
						title={"Suspend Customer"}
						isOpen={suspend}
						onClick={SuspendUser}
						onClose={closeSuspend}
					/>
					<Modal
						isOpen={sucess}
						onClose={closeSucess}
						title={"You have successfully suspended this customer"}
					/>
					<div className="mx-10    mt-10 mb-10 flex gap-8">
						<Card2
							imageSource={userData?.userCollection?.photo}
							description="Phone Number"
							description2="Email"
							description3="Adress"
							// description2="Email"
							iconClass="fas fa-info-circle" // Replace with n
							descriptionSub3={userData?.userCollection?.address}
							iconLabel="Information"
							numbers={userData?.userCollection?.email}
							amount={userData?.userCollection?.phone}
						/>
					</div>

					<div className="rounded-lg shadow-lg bg-white my-20 mx-10">
						<div className="px-10 py-5">
							<div className=" bg-white   px-10 py-5 w-[100%] justify-between ">
								<div className=" bg-white w-full justify-between flex h-full">
									<div className="flex w-3/4">
										<form>
											<label
												for="default-search"
												class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white w-full"
											>
												Search
											</label>
											<div class="relative w-96">
												<div class="absolute w-full inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
													<svg
														aria-hidden="true"
														class="w-5 h-5 text-gray-500 dark:text-gray-400"
														fill="none"
														stroke="currentColor"
														viewBox="0 0 24 24"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
															d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
														></path>
													</svg>
												</div>
												<input
													type="search"
													id="default-search"
													class="block w-full p-2 pl-10  text-sm text-[#000000] rounded   dark:focus:ring-blue-500 border-2 border-[#CBD5E1] "
													placeholder="Search for employee or job title"
													value={search}
													onChange={(event) =>
														handleSearch(
															event,
															userData?.customer_orders,
															"name														"
														)
													}
												/>
											</div>
										</form>
									</div>
									<div className="flex w-1/4">
										<div className="relative text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
											<select className="absolute inset-y-0 left-0 pl-4 pr-2 text-sm text-gray-900 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-[#CBD5E1] ">
												<option value="">Select Action</option>
												<option value="edit">Edit</option>
												<option value="delete">Delete</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="bg-gray-200 ">
							<div className="ml-10 grid grid-cols-5 p-2 bg-gray-200 py-8 text-[#475569] font-semibold">
								<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
									Name
								</div>
								<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
									Price
								</div>
								<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
									Quantity
								</div>
								<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
									Address
								</div>

								<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider mx-10">
									Status
								</div>
							</div>
						</div>
						{search === ""
							? paginatedData?.map((row, index) => {
									return (
										<div
											className={`ml-10 grid grid-cols-5 py-4 border-b`}
											key={index}
										>
											<div className="gap-2 flex text-left text-sm">
												<img
													className="w-9 h-9 rounded-md"
													src={row.productDetails.product_details?.photos[0]}
													alt={"imageAlt"}
												/>
												<button className="focus:outline-none text-[#2563EB] font-medium">
													{row?.product_details?.name}
												</button>
											</div>

											<div className="text-left text-sm">
												₦ {row?.productDetails.product_details?.price}
											</div>
											<div className="text-left text-sm">
												{row.productDetails.quantity}
											</div>
											<div className="text-left text-sm">
												{row.productDetails.customer_address.address}
											</div>

											<div className="text-[#007BE0]   text-[12px] font-semibold leading-7 mx-10">
												{row.productDetails.order_statu}
											</div>
										</div>
									);
							  })
							: searchResults?.map((row, index) => {
									return (
										<div
											className={`grid grid-cols-6 py-4 border-b`}
											key={index}
										>
											<div className="cell ml-3">
												<input
													type="checkbox"
													checked={selectedRows.includes(index)}
												/>
											</div>
											<div className="gap-2 flex text-left text-sm">
												<img
													className="w-9 h-9 rounded-md"
													src={row.product_details?.photos[0]}
													alt={"imageAlt"}
												/>
												<button className="focus:outline-none text-[#2563EB] font-medium">
													{row?.product_details?.name}
												</button>
											</div>

											<div className="text-left text-sm">
												₦ {row?.product_details?.price}
											</div>
											<div className="text-left text-sm">{row.quantity}</div>
											<div className="text-left text-sm">{row.trackingID}</div>
											<div className="text-left text-sm">{row.orderTotal}</div>

											<div className="text-[#007BE0]   text-[12px] font-semibold leading-7 mx-10">
												{row.order_status}
											</div>
										</div>
									);
							  })}
						<div className="mb-5">
							<button
								onClick={() => handlePageChange(currentPage - 1)}
								disabled={currentPage === 0}
							>
								<FontAwesomeIcon icon={faChevronLeft} /> Previous
							</button>

							<span>{`Page ${currentPage + 1} of ${totalPages}`}</span>

							<button
								onClick={() => handlePageChange(currentPage + 1)}
								disabled={currentPage === totalPages - 1}
							>
								Next <FontAwesomeIcon icon={faChevronRight} />
							</button>
						</div>
						<div className="p-5 flex items-center justify-between mt-5">
							{/* ... (Pagination and Show rows elements remain the same) */}
						</div>
					</div>
				</Layout>
			)}
		</div>
	);
};

export default UserDetail;
