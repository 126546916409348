/** @format */
import React, { useState, useEffect } from "react";
import "../atoms/purchase.css";
import Layout from "../atoms/layout";

import ride2 from "../assets/car2.svg";
// import ride3 from "../assets/car1.svg";
import { useNavigate } from "react-router-dom";
import BlueCard from "../atoms/BlueCard";
import { getRider } from "../services/requests";
import Card from "../atoms/Card";
import Loader from "../atoms/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
const Ridder = () => {
	const navigate = useNavigate();
	const [selectedRows, setSelectedRows] = useState([]);
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState([]);

	async function getRidders() {
		try {
			const result = sessionStorage.getItem("myToken");

			const newToken = JSON.parse(result);
			const { data } = await getRider(newToken);
			setUser(data?.data);
			setLoading(false);
		} catch (err) {
			setLoading(false);
			console.error(err);
		}
	}
	useEffect(() => {
		getRidders();
	}, []);
	const formatDateAndTime = (dateTimeString) => {
		const dateTime = new Date(dateTimeString);

		// Separate date and time
		const date = dateTime.toLocaleDateString(undefined, {
			year: "numeric",
			month: "long",
			day: "numeric",
		});
		const time = dateTime.toLocaleTimeString(undefined, {
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
		});

		return { date, time };
	};
	const [search, setSearch] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const handleSearch = (event) => {
		const searchText = event.target.value;
		setSearch(searchText);

		const filteredData = user?.filter((user) =>
			user?.username?.toLowerCase().includes(searchText.toLowerCase())
		);

		setSearchResults(filteredData);
	};
	const [selectedStatus, setSelectedStatus] = useState("Status");

	// Function to handle changes in the dropdown
	const handleStatusChange = (event) => {
		setSelectedStatus(event.target.value);
	};
	const filteredData =
		search === ""
			? user?.filter(
					(row) => selectedStatus === "Status" || row.action === selectedStatus
			  )
			: searchResults?.filter(
					(row) => selectedStatus === "Status" || row.action === selectedStatus
			  );
	const itemsPerPage = 4; // Change this value based on your requirement
	const [currentPage, setCurrentPage] = useState(0);

	const paginatedData = filteredData
		.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
		.map((item) => {
			console.log(item, "datasss");
			return {
				productDetails: item,
			};
		});
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const totalPages = Math.ceil(filteredData.length / itemsPerPage);
	return (
		<div>
			{loading ? (
				<Loader />
			) : (
				<Layout>
					<div className="flex justify-end mt-5 mx-10 gap-5"></div>
					<div className="mx-10    mt-10 mb-10 lg:flex sm:grid gap-8">
						<Card
							imageSource={ride2}
							description="Total Ridders"
							iconClass="fas fa-info-circle"
							iconLabel="Information"
							amount={user?.length}
						/>
						<Card
							imageSource={ride2}
							description="Completed Ridders"
							iconClass="fas fa-info-circle"
							iconLabel="Information"
							amount="0"
						/>
						<BlueCard
							imageSource={ride2}
							description="Pending Rides"
							amounts="0"
							iconClass="fas fa-info-circle"
							iconLabel="Information"
						/>
					</div>
					<div className="rounded-lg shadow-lg bg-white my-20 mx-10">
						<div className="px-10 py-5">
							<div className=" bg-white   px-10 py-5 w-[100%] justify-between ">
								<div className=" bg-white w-full justify-between flex h-full">
									<div className="flex w-3/4">
										<form>
											<label
												for="default-search"
												class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white w-full"
											>
												Search
											</label>
											<div class="relative w-96">
												<div class="absolute w-full inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
													<svg
														aria-hidden="true"
														class="w-5 h-5 text-gray-500 dark:text-gray-400"
														fill="none"
														stroke="currentColor"
														viewBox="0 0 24 24"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
															d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
														></path>
													</svg>
												</div>
												<input
													value={search}
													onChange={handleSearch}
													type="search"
													id="default-search"
													class="block w-full p-2 pl-10  text-sm text-[#000000] rounded   dark:focus:ring-blue-500 border-2 border-[#CBD5E1] "
													placeholder="Search for employee or job title"
												/>
											</div>
										</form>
									</div>
									<div className="flex w-1/4 gap-4">
										<div className="relative ml-2 rounded-md text-[#475569]">
											<select
												className="h-[36px] border-2 border-[#CBD5E1] w-full bg-white rounded-md py-2 pl-8 pr-3 sm:text-sm sm:leading-5"
												value={selectedStatus}
												onChange={handleStatusChange}
											>
												<option value="Status">ALL</option>
												<option value="approve">approve</option>
												<option value="pending">pending</option>
											</select>
										</div>
										<div className="relative text-center text-xs font-semibold text-[#475569] uppercase tracking-wider">
											Total Ridders
											<span>({user?.length})</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="grid grid-cols-4 p-2 bg-gray-200 py-8 text-[#475569] font-semibold ">
							
							<div className="text-center text-xs font-semibold text-[#475569] uppercase tracking-wider ml-10">
								Ridder Name
							</div>
							<div className="text-center text-xs font-semibold text-[#475569] uppercase tracking-wider">
								Email address
							</div>
							<div className="text-center  text-xs font-semibold text-[#475569] uppercase tracking-wider">
								Date joined
							</div>

							<div className="text-center text-xs font-semibold text-[#475569] uppercase tracking-wider">
								Status
							</div>
						</div>
						{paginatedData?.map((row, index) => {
							console.log(row, "hey");
							return (
								<div className={`grid grid-cols-4 ml-10 py-4 border-b`} key={index}>
									
									<div className="text-center text-sm">
										<button
											className="focus:outline-none text-[#2563EB] font-medium"
											onClick={() => {
												sessionStorage.setItem(
													"selectedIDs",
													JSON.stringify([
														row.productDetails.ownerId ||
															row?.productDetails.id,
													])
												);
												navigate("/ridderdetails");
											}}
										>
											{row.productDetails.username}
										</button>
									</div>
									<div className="text-center text-sm whitespace-pre-line break-words">
										{row.productDetails.email}
									</div>
									<div className="text-center text-sm">
										{formatDateAndTime(row?.productDetails.createdAt).date}
									</div>
									{/* <div className="text-center text-sm">{row.trackingID}</div>
											<div className="text-center text-sm">{row.orderTotal}</div> */}

									<div className="text-[#007BE0]  text-center text-[12px] font-semibold leading-7 mx-10">
										{row.productDetails.action}
									</div>
								</div>
							);
						})}
						<div className="mb-5">
							<button
								onClick={() => handlePageChange(currentPage - 1)}
								disabled={currentPage === 0}
							>
								<FontAwesomeIcon icon={faChevronLeft} /> Previous
							</button>

							<span>{`Page ${currentPage + 1} of ${totalPages}`}</span>

							<button
								onClick={() => handlePageChange(currentPage + 1)}
								disabled={currentPage === totalPages - 1}
							>
								Next <FontAwesomeIcon icon={faChevronRight} />
							</button>
						</div>
						<div className="p-5 flex items-center justify-between mt-5">
							{/* ... (Pagination and Show rows elements remain the same) */}
						</div>
					</div>
				</Layout>
			)}
		</div>
	);
};

export default Ridder;
