/** @format */

// /** @format */

// import React, { useState } from "react";
import logo from "../../assets/logo.png";
import SideBarItem from "./SideBarItem";
import home from "../../assets/dashboard.svg";
import order from "../../assets/order.svg";
import Settings from "../../assets/setting.svg";
import logout from "../../assets/logout.svg";
import { NavLink } from "react-router-dom";
import vendor from "../../assets/vendor.svg";
import rider from "../../assets/ridder.svg";
import user from "../../assets/Users.svg";
import inventroy from "../../assets/inventroy.svg";
// // import "./side"
// function SideBar() {

// 	const [isSidebarOpen, setSidebarOpen] = useState(true);

// 	const toggleSidebar = () => {
// 	  setSidebarOpen(!isSidebarOpen);
// 	};

// 	return (
// 	    <div className={`w-[340px] h-screen bg-white border-r border-[#F1F5F9] pt-6 ${isSidebarOpen ? '' : 'hidden'}`}>
// 			<img className="pl-10" alt="logo" src={logo} />
// 			<div className="mt-[45px] flex flex-col gap-4">
// 				<NavLink to="/dashboard" activeClassName="text-blue-500">
// 					<SideBarItem title="Dashboard" icon={home} />
// 				</NavLink>
// 				<NavLink to="/orders" activeClassName="text-blue-500">
// 					<SideBarItem title="Orders" icon={order} />
// 				</NavLink>
// 				<NavLink to="/vendor" activeClassName="text-blue-500">
// 					<SideBarItem title="Vendors" icon={vendor} />
// 				</NavLink>
// 				<NavLink to="/ridder" activeClassName="text-blue-500">
// 					<SideBarItem title="Riders" icon={rider} />
// 				</NavLink>
// 				<NavLink to="/user" activeClassName="text-blue-500">
// 					<SideBarItem title="Users" icon={user} />
// 				</NavLink>
// 				{/* <SideBarItem title="Messages" icon={message} /> */}
// 				<NavLink to="/allproducts" activeClassName="text-blue-500">
// 					<SideBarItem title="Products" icon={inventroy} />
// 				</NavLink>

// 				<SideBarItem title="Settings" icon={Settings} />
// 				<div className="mt-[200px] border-t border-[#F1F5F9]">
// 					<SideBarItem title="Logout" icon={logout} />
// 				</div>
// 			</div>
// 			<button className="fixed top-4 right-4 md:hidden" onClick={toggleSidebar}>
//         Toggle Sidebar
//       </button>
// 		</div>
// 	);
// }

// export default SideBar;
import React, { useState } from "react";

const Sidebar = () => {
	const [open, setOpen] = useState(false);

	const toggleSidebar = () => {
		setOpen(!open);
	};

	return (
		<div className="font-sans lg:h-screen bg-gray-100">
			{/* Navbar */}
			<nav className="lg:bg-white p-4 bg-black">
				<div className="container mx-auto flex justify-between items-center">
					<div>
						{" "}
						<img className="pl-10" alt="logo" src={logo} />
					</div>
					<div className="sm:hidden">
						<button
							onClick={toggleSidebar}
							className="text-white focus:outline-none"
						>
							<svg
								className="h-6 w-6"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M4 6h16M4 12h16m-7 6h7"
								></path>
							</svg>
						</button>
					</div>
				</div>
			</nav>

			{/* Sidebar and Content */}
			<div className="flex h-full  bg-white">
				<aside
					className={`hidden sm:flex flex-col w-64 bg-white ${
						open ? "" : "hidden"
					}`}
				>
					{/* <div className="flex items-center justify-center h-16 bg-gray-900 text-white">
            <span className="text-2xl font-semibold">Sidebar</span>
          </div> */}
					<nav className=" flex-1 overflow-x-hidden overflow-y-auto p-4">
						<NavLink to="/dashboard" activeClassName="text-blue-500">
							<SideBarItem title="Dashboard" icon={home} />
						</NavLink>
						<NavLink to="/orders" activeClassName="text-blue-500">
							<SideBarItem title="Orders" icon={order} />
						</NavLink>
						<NavLink to="/vendor" activeClassName="text-blue-500">
							<SideBarItem title="Vendors" icon={vendor} />
						</NavLink>
						<NavLink to="/ridder" activeClassName="text-blue-500">
							<SideBarItem title="Riders" icon={rider} />
						</NavLink>
						<NavLink to="/user" activeClassName="text-blue-500">
							<SideBarItem title="Users" icon={user} />
						</NavLink>
						<NavLink to="/allproducts" activeClassName="text-blue-500">
							<SideBarItem title="Products" icon={inventroy} />
						</NavLink>

						<SideBarItem title="Settings" icon={Settings} />
						<div className="mt-[200px]  border-t border-[#F1F5F9]">
							
									<NavLink to="/">
										<SideBarItem title="Logout" icon={logout} />
									</NavLink>
								</div>

						<button
							className="fixed top-4 right-4 md:hidden"
							onClick={toggleSidebar}
						>
							{/* Toggle Sidebar */}
						</button>
					</nav>
				</aside>

				<div className="sm:hidden">
					<button
						onClick={toggleSidebar}
						className="fixed top-4 right-4 p-2 text-white focus:outline-none"
					>
						<svg
							className="h-6 w-6"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M4 6h16M4 12h16m-7 6h7"
							></path>
						</svg>
					</button>
				</div>

				{open && (
					<>
						<div
							onClick={toggleSidebar}
							className="sm:hidden fixed inset-0 z-40 bg-black opacity-50"
						></div>
						<div className="sm:hidden fixed inset-y-0 left-0 z-50 w-64 bg-white overflow-y-auto transform translate-x-0 ease-in-out transition-transform">
							<nav className="flex-1 overflow-x-hidden overflow-y-auto p-4 bg-black" >
								<NavLink to="/dashboard" activeClassName="text-blue-500">
									<SideBarItem title="Dashboard" icon={home} />
								</NavLink>
								<NavLink to="/orders" activeClassName="text-blue-500">
									<SideBarItem title="Orders" icon={order} />
								</NavLink>
								<NavLink to="/vendor" activeClassName="text-blue-500">
									<SideBarItem title="Vendors" icon={vendor} />
								</NavLink>
								<NavLink to="/ridder" activeClassName="text-blue-500">
									<SideBarItem title="Riders" icon={rider} />
								</NavLink>
								<NavLink to="/user" activeClassName="text-blue-500">
									<SideBarItem title="Users" icon={user} />
								</NavLink>
								<NavLink to="/allproducts" activeClassName="text-blue-500">
									<SideBarItem title="Products" icon={inventroy} />
								</NavLink>
								<SideBarItem title="Settings" icon={Settings} />
								<div className="mt-[200px]  border-t border-[#F1F5F9]">
							
									<NavLink to="/">
										<SideBarItem title="Logout" icon={logout} />
									</NavLink>
								</div>

								<button
									className="fixed top-4 right-4 md:hidden"
									onClick={toggleSidebar}
								>
									{/* Toggle Sidebar */}
								</button>
							</nav>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default Sidebar;
