/** @format */

import React, { useState, useEffect } from "react";
import Layout from "../atoms/layout";
import Card from "../atoms/Card";
import EmployeeData from "../atoms/Orders";
import MultiHeaderCard from "../atoms/MultiHeaderCard";
import MultiCard from "../atoms/MultiCard";
import axios from "axios";
import Loader from "../atoms/Loader";
import Sidebar from "../atoms/layout/SideBar";

function OrderDetaills() {
	// const [selectedRows, setSelectedRows] = useState([]);
	const [id, setId] = useState("");
	const [userData, setUserData] = useState("");
	const [loading, setLoading] = useState(false);

	const headers = [
		{
			main: "Order Details",
			subHeaders: [
				{
					title: "Subheader 1",
					content: "Content for Subheader 1",
				},
				{
					title: "Subheader 2",
					content: "Content for Subheader 2",
				},
			],
		},
		{
			main: "Status",
			subHeaders: [
				{
					title: "Subheader 1",
					content: "Content for Subheader 1",
				},
				{
					title: "Subheader 2",
					content: "Content for Subheader 2",
				},
			],
		},
		{
			main: "Payment Details",
			subHeaders: [
				{
					title: "Subheader 1",
					content: "Content for Subheader 1",
				},
				{
					title: "Subheader 2",
					content: "Content for Subheader 2",
				},
			],
		},
	];
	console.log(id[0], "id");
	const orderDetails = async () => {
		setLoading(true);
		try {
			const result = sessionStorage.getItem("myToken");

			const newToken = JSON.parse(result);
			const API_URL = `https://flashserve-api-761656c703cc.herokuapp.com/api/v1/admin/orders/${id[0]}`;
			const config = {
				headers: {
					Authorization: `Bearer ${newToken}`,
					"Content-Type": "application/json",
				},
			};
			const response = await axios.get(API_URL, config);
			console.log(response?.data?.data, "hello setups");
			setUserData(response?.data?.data);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error(error, "errorrr"); // Use console.error for error messages
		}
	};
	useEffect(() => {
		// Retrieve stored checkedRowsData from sessionStorage
		const savedCheckedRowsData = JSON.parse(
			sessionStorage.getItem("selectedIDs")
		);
		if (savedCheckedRowsData) {
			setId(savedCheckedRowsData);
			console.log(
				"Retrieved Checked Rows Data on Page B:",
				savedCheckedRowsData
			);
		}
	}, []);
	useEffect(() => {
		orderDetails();
	}, [id]);
	const [isSmallScreen, setIsSmallScreen] = useState(false);

	// Check screen size on mount and on window resize
	const handleResize = () => {
		setIsSmallScreen(window.innerWidth <= 768); // Adjust the threshold according to your needs
	};

	useEffect(() => {
		handleResize(); // Initial check
		window.addEventListener("resize", handleResize);

		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	console.log(userData, "data");
	return (
		<div>
			{isSmallScreen ? (
				// Render alternative layout for small screens
				<div>
					<Sidebar />
					{loading ? (
						<Loader />
					) : (
						<div className="container mx-auto p-4">
							<div className="lg:flex gap-8 grid ">
								<MultiHeaderCard headers={headers} userData={userData} />
								<MultiCard userData={userData} />
							</div>
							<div>
								<div className="rounded-lg shadow-lg bg-white ">
									<div className="px-10 py-5">
										<div className=" bg-white   px-10 py-5 w-[100%] justify-between ">
											<div className=" bg-white w-full justify-between flex h-full">
												<div className="flex">
													<form>
														<label
															for="default-search"
															class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white w-full"
														>
															Search
														</label>
														<div class="relative w-full">
															<div class="absolute w-full inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
																<svg
																	aria-hidden="true"
																	class="w-5 h-5 text-gray-500 dark:text-gray-400"
																	fill="none"
																	stroke="currentColor"
																	viewBox="0 0 24 24"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path
																		stroke-linecap="round"
																		stroke-linejoin="round"
																		stroke-width="2"
																		d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
																	></path>
																</svg>
															</div>
															<input
																type="search"
																id="default-search"
																class="block w-full p-2 pl-10  text-sm text-gray-900   rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-[#CBD5E1] dark:bg-[#ffffff] dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 border-2 border-[#CBD5E1] "
																placeholder="Search"
																required
															/>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
									<div className="grid grid-cols-4 p-2 bg-gray-200 py-8 text-[#475569] font-semibold">
										<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
											Product
										</div>

										<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
											Item Price
										</div>
										<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
											Total Price
										</div>

										<div className="text-center text-xs font-semibold text-[#475569] uppercase tracking-wider">
											Status
										</div>
									</div>

									<div className={`grid grid-cols-4 py-4 border-b`}>
										{/* <div className="cell ml-3">
								<input type="checkbox" />
							</div> */}
										<div className="ml-2 gap-2 flex text-left text-sm">
											<img
												className="w-9 h-9 rounded-md"
												src={userData.product_details?.photos[0]}
												alt={"imageAlt"}
											/>
											<button className="focus:outline-none text-[#2563EB] font-medium">
												{userData.product_details?.name}
											</button>
										</div>

										<div className="text-center text-sm">
											₦ {userData.product_details?.price}
										</div>
										<div className="text-left text-sm">
											₦ {userData.product_details?.price}
										</div>

										<div className="text-[#FCA800] bg-[#FEF4CB] text-[12px] font-semibold leading-7 mx-5">
											{userData.order_details?.orderstatus}
										</div>
									</div>

									<div className="p-5 flex items-center justify-between mt-5"></div>
								</div>
							</div>
						</div>
					)}
				</div>
			) : (
				// Render your default layout for larger screens
				<div>
					{loading ? (
						<Loader />
					) : (
						<div>
							{loading ? (
								<Loader />
							) : (
								<Layout>
									<div className="container mx-auto p-4">
										<div className="flex gap-8 my-20 mx-10 ">
											<MultiHeaderCard headers={headers} userData={userData} />{" "}
											<MultiCard userData={userData} />
										</div>
										<div>
											<div className="rounded-lg shadow-lg bg-white my-20 mx-10">
												<div className="px-10 py-5">
													<div className=" bg-white   px-10 py-5 w-[100%] justify-between ">
														<div className=" bg-white w-full justify-between flex h-full">
															<div className="flex">
																<form>
																	<label
																		for="default-search"
																		class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white w-full"
																	>
																		Search
																	</label>
																	<div class="relative w-96">
																		<div class="absolute w-full inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
																			<svg
																				aria-hidden="true"
																				class="w-5 h-5 text-gray-500 dark:text-gray-400"
																				fill="none"
																				stroke="currentColor"
																				viewBox="0 0 24 24"
																				xmlns="http://www.w3.org/2000/svg"
																			>
																				<path
																					stroke-linecap="round"
																					stroke-linejoin="round"
																					stroke-width="2"
																					d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
																				></path>
																			</svg>
																		</div>
																		<input
																			type="search"
																			id="default-search"
																			class="block w-full p-2 pl-10  text-sm text-gray-900   rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-[#CBD5E1] dark:bg-[#ffffff] dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 border-2 border-[#CBD5E1] "
																			placeholder="Search for employee or job title"
																			required
																		/>
																	</div>
																</form>
															</div>
															<div className="flex"></div>
														</div>
													</div>
												</div>
												<div className="bg-gray-200">
													<div className="grid grid-cols-7 p-2 bg-gray-200 py-8 text-[#475569] font-semibold ml-10">
														<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider"></div>
														<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
															Product
														</div>
														<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
															Quantity
														</div>
														<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
															Order #
														</div>
														<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
															Item Price
														</div>
														<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
															Total Price
														</div>
														<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
															Tracking ID
														</div>
														<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
															Status
														</div>
													</div>
												</div>

												<div className={`grid grid-cols-7 py-4 border-b ml-10`}>
													<div className=" gap-2 flex text-left text-sm">
														<img
															className="w-9 h-9 rounded-md"
															src={userData.product_details?.photos[0]}
															alt={"imageAlt"}
														/>
														<button className="focus:outline-none text-[#2563EB] font-medium">
															{userData.product_details?.name}
														</button>
													</div>
													<div className="text-left text-sm">
														{userData.order_details?.quantity}
													</div>
													<div className="text-left text-sm">
														{userData.order_details?.orderId}
													</div>
													<div className="text-left text-sm">
														₦ {userData.product_details?.price}
													</div>
													<div className="text-left text-sm">
														₦ {userData.product_details?.price}
													</div>

													<div className="relative text-left text-xs font-semibold text-[#475569] uppercase tracking-wider"></div>

													<div className="text-[#FCA800] bg-[#FEF4CB] text-[12px] font-semibold leading-7 mx-5">
														{userData.order_details?.orderstatus}
													</div>
												</div>

												<div className="p-5 flex items-center justify-between mt-5">
								
												</div>
											</div>
										</div>
									</div>
								</Layout>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
}

export default OrderDetaills;
