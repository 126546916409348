/** @format */

import React from "react";

function RedButton({ onClick, label, ...props }) {
	return (
		<div {...props} className="">
			<button
				onClick={onClick}
				className="text-[16px] bg-[#EB5757] font-semibold text-[#FFFFFF] py-2 px-2 rounded-md w-[200px]"
			>
				{label}
			</button>
		</div>
	);
}

export default RedButton;
