/** @format */

import React, { useState, useEffect } from "react";
import Layout from "../atoms/layout";
import Card from "../atoms/Card";
import icon1 from "../assets/icontwo.png";
import icon4 from "../assets/iconthree.png";
import icon2 from "../assets/ridder.png";
import icon3 from "../assets/iconone.png";
import plus from "../assets/plus.png";
import "./dashboard.css";
import RecentOrder from "../atoms/RecentOrder";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import Document from "../atoms/layout/Document";
// import BlueCard from "../atoms/BlueCard";
import {
	getCategory,
	getOrder,
	getProducts,
	getRider,
	getUser,
	getVendor,
} from "../services/requests";

import axios from "axios";
import Loader from "../atoms/Loader";
import Sidebar from "../atoms/layout/SideBar";

const Dashboard = () => {
	const [isDocumentOpen, setDocumentOpen] = useState(false);
	const [user, setUser] = useState([]);
	const [vendor, setVendor] = useState([]);
	const [ridder, setRidder] = useState([]);
	const [orderData, setOrderData] = useState([]);
	const [allData, setAllData] = useState([]);
	const [products, setProducts] = useState([]);
	const [category, setCategory] = useState([]);
	const [completed, setCompleted] = useState([]);
	const [loading, setLoading] = useState(false);
	const openDocumentModal = () => {
		setDocumentOpen(true);
	};
	const itemsPerPage = 3; // Change this value based on your requirement
	const [currentPage, setCurrentPage] = useState(0);

	const paginatedData = allData
	.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
	.map((item) => {
		return {
			productDetails: item.product_details,
			createdAt: item.createdAt,
			order_status: item.order_status,
		};
	});

	// Handle page navigation
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};

	// Calculate the total number of pages
	const totalPages = Math.ceil(allData.length / itemsPerPage);

	const closeDocumentModal = () => {
		setDocumentOpen(false);
	};
	console.log(paginatedData, "data pag");
	async function getProduct() {
		try {
			const result = sessionStorage.getItem("myToken");

			const newToken = JSON.parse(result);
			const { data } = await getProducts(newToken);
			setProducts(data?.data);
			setLoading(false);
		} catch (err) {
			console.error(err);
			setLoading(false);
		}
	}
	async function Categoryies() {
		setLoading(true);
		try {
			setLoading(false);
			const result = sessionStorage.getItem("myToken");

			const newToken = JSON.parse(result);
			const { data } = await getCategory(newToken);
			setCategory(data?.data);
		} catch (err) {
			setLoading(false);
			console.error(err, "error cate");
		}
	}
	async function getUsers() {
		setLoading(true);
		try {
			const result = sessionStorage.getItem("myToken");

			const newToken = JSON.parse(result);
			const { data } = await getUser(newToken);
			setUser(data?.data);
			console.error(data, "data");
			setLoading(false);
		} catch (err) {
			console.error(err);
			setLoading(false);
		}
	}
	async function getVendors() {
		setLoading(true);
		try {
			const result = sessionStorage.getItem("myToken");

			const newToken = JSON.parse(result);
			const { data } = await getVendor(newToken);
			setVendor(data?.data);
			console.error(data, "data");
			setLoading(false);
		} catch (err) {
			console.error(err);
			setLoading(false);
		}
	}
	async function getRidders() {
		setLoading(true);
		try {
			const result = sessionStorage.getItem("myToken");

			const newToken = JSON.parse(result);
			const { data } = await getRider(newToken);
			setRidder(data?.data);
			console.error(data, "data riders");
			setLoading(false);
		} catch (err) {
			console.error(err);
			setLoading(false);
		}
	}
	async function getOrders() {
		setLoading(true);
		try {
			const result = sessionStorage.getItem("myToken");

			const newToken = JSON.parse(result);
			const { data } = await getOrder(newToken);
			console.log(data?.data, "datata");
			setAllData(data?.data);
			const filteredData = data?.data?.filter(
				(item) => item.status === "pending"
			);
			setOrderData(filteredData);
			console.log(filteredData, "fill");
			const filteredDataComplete = data?.data?.filter(
				(item) => item.status === "completed"
			);

			setCompleted(filteredDataComplete);
			setLoading(false);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	}

	useEffect(() => {
		getOrders();
		getProduct();
		Categoryies();
		getUsers();
		getVendors();
		getRidders();
	}, []);

	const addCategory = async (payload) => {
		try {
			const result = sessionStorage.getItem("myToken");

			const newToken = JSON.parse(result);
			const API_URL =
				"https://flashserve-api-761656c703cc.herokuapp.com/api/v1/admin/categories/create";
			const config = {
				headers: {
					Authorization: `Bearer ${newToken}`,
					"Content-Type": "application/json",
				},
			};

			// Use the payload from the child component
			const response = await axios.post(API_URL, payload, config);
			console.log(response, "hello");
		} catch (error) {
			console.log(error, "err");
		}
	};
	const [isSmallScreen, setIsSmallScreen] = useState(false);

	// Check screen size on mount and on window resize
	const handleResize = () => {
		setIsSmallScreen(window.innerWidth <= 768); // Adjust the threshold according to your needs
	};

	useEffect(() => {
		handleResize(); // Initial check
		window.addEventListener("resize", handleResize);

		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div>
			{isSmallScreen ? (
				// Render alternative layout for small screens
				<div>
					<Sidebar />
					{loading ? (
						<Loader />
					) : (
						<div>
							<div className="container  mx-auto p-4">
								<div className="w-full">
									<div className="mb-10 text-[#333333] text-[20px] font-semibold leading-7">
										<span>Categories</span>
									</div>
									<div className="lg:flex sm:grid">
										<div className="lg:flex sm:grid ">
											{category?.map((newcat, id) => {
												return (
													<div
														className="mb-5 lg:mr-10 gap-8 w-full md:w-1/2"
														key={id}
													>
														<img
															className="rounded-md lg:h-48 sm:w-40  lg:w-full mr-5"
															src={newcat?.catImage}
															alt="hello"
														/>
													</div>
												);
											})}
										</div>

										<Card
											onClick={openDocumentModal}
											imageSource={plus}
											amount="Add Category"
											className="h-48 w-[18%] gap-5 ml-30"
										/>
									</div>
								</div>

								<div className="w-full mx-auto mt-20 sm:mt-4 mb-10 sm:mb-4 lg:flex sm:grid gap-8">
									<Card
										// onClick={() => {
										// 	navigate("/dashboardorder");
										// }}
										imageSource={icon1}
										// description="Customers Sales"
										description="Total Vendor"
										iconClass="fas fa-info-circle"
										iconLabel="Information"
										amount={vendor?.length}
										// amount="₦0"
									/>
									<Card
										// onClick={() => {
										// 	navigate("/dashboardorder");
										// }}
										imageSource={icon2}
										// description="Customers Sales"
										description="Total Ridders"
										iconClass="fas fa-info-circle"
										iconLabel="Information"
										amount={ridder?.length}
										// amount="₦0"
									/>
									<Card
										// onClick={() => {
										// 	navigate("/dashboardorder");
										// }}
										imageSource={icon3}
										// description="Customers Sales"
										description="Total customers"
										iconClass="fas fa-info-circle"
										iconLabel="Information"
										amount={user?.length}
										// amount="₦0"
									/>
									<Card
										imageSource={icon4}
										description="Total Order"
										amount={orderData?.length}
										iconClass="fas fa-info-circle"
										iconLabel="Information"
									/>
								</div>
								<Document
									isOpen={isDocumentOpen}
									onClose={closeDocumentModal}
									onAddCategory={addCategory}
								/>
								<div className=" mx-auto mt-10 mb-10 flex gap-5">
									<div className="w-1/2 rounded-md px-10 flex-1 overflow-hidden shadow-lg  bg-[#ffffff]">
										<div className="text-left mt-5 ">
											<span className="text-left text-[#333333] text-16px font-semibold">
												Recent Orders
											</span>
										</div>
										{allData?.map((data, index) => (
											<RecentOrder key={index} data={data} />
										))}
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			) : (
				// Render your default layout for larger screens
				<div>
					{loading ? (
						<Loader />
					) : (
						<Layout>
							<div className="container  mx-auto p-4">
								<div className="w-full  md:w-1/2">
									<div className="mb-10 text-[#333333] text-[20px] font-semibold leading-7">
										<span>Categories</span>
									</div>
									<div className="lg:flex sm:grid scrollable-div-x">
										<div className="lg:flex sm:grid ">
											{category?.map((newcat, id) => {
												return (
													<div
														className="mb-5 lg:mr-10 gap-8 w-full md:w-1/2"
														key={id}
													>
														<img
															className="rounded-md h-48 sm:w-40  lg:w-full mr-5"
															src={newcat?.catImage}
															alt="hello"
														/>
													</div>
												);
											})}
										</div>

										<Card
											onClick={openDocumentModal}
											imageSource={plus}
											title="Add Category"
											className="h-48 w-[18%] gap-5 ml-30"
										/>
									</div>
								</div>

								<div className="w-full mx-auto mt-20 sm:mt-4 mb-10 sm:mb-4 lg:flex sm:grid gap-8">
									<Card
										// onClick={() => {
										// 	navigate("/dashboardorder");
										// }}
										imageSource={icon1}
										// description="Customers Sales"
										description="Total Vendor"
										iconClass="fas fa-info-circle"
										iconLabel="Information"
										amount={vendor?.length}
										// amount="₦0"
									/>
									<Card
										// onClick={() => {
										// 	navigate("/dashboardorder");
										// }}
										imageSource={icon2}
										// description="Customers Sales"
										description="Total Ridders"
										iconClass="fas fa-info-circle"
										iconLabel="Information"
										amount={ridder?.length}
										// amount="₦0"
									/>
									<Card
										// onClick={() => {
										// 	navigate("/dashboardorder");
										// }}
										imageSource={icon3}
										// description="Customers Sales"
										description="Total customers"
										iconClass="fas fa-info-circle"
										iconLabel="Information"
										amount={user?.length}
										// amount="₦0"
									/>
									<Card
										imageSource={icon4}
										description="Total Order"
										amount={orderData?.length}
										iconClass="fas fa-info-circle"
										iconLabel="Information"
									/>
								</div>
								<Document
									isOpen={isDocumentOpen}
									onClose={closeDocumentModal}
									onAddCategory={addCategory}
								/>
								<div className=" mx-auto mt-10 mb-10 flex gap-5">
									<div className="w-1/2 rounded-md px-10 flex-1 overflow-hidden shadow-lg  bg-[#ffffff]">
										<div className="text-left mt-5 ">
											<span className="text-left text-[#333333] text-16px font-semibold">
												Recent Orders
											</span>
										</div>
										{paginatedData?.map((data, index) => (
											<RecentOrder key={index} data={data} />
										))}
										<div className="mb-5">
											<button
												onClick={() => handlePageChange(currentPage - 1)}
												disabled={currentPage === 0}
											>
												    <FontAwesomeIcon icon={faChevronLeft} /> Previous
											</button>

											<span> {`Page ${currentPage + 1} of ${totalPages}`} </span>

											<button
												onClick={() => handlePageChange(currentPage + 1)}
												disabled={currentPage === totalPages - 1}
											>
											 Next <FontAwesomeIcon icon={faChevronRight} />
											</button>
										</div>
									</div>
								</div>
							</div>
						</Layout>
					)}
				</div>
			)}
		</div>
	);
};

export default Dashboard;
