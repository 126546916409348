/** @format */
import React, { useState } from "react";
import cans from "../../assets/cans.png";
import Modal from "../Modal";

import done from "../../assets/done.png";

function Document({ isOpen, onClose, onAddCategory }) {
	const [open, setOpen] = useState(false);
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [image, setImage] = useState("");
	const [selectedFile, setSelectedFile] = useState(null);
	const [upload, setUpload] = useState(false);

	const openDocumentModal = () => {
		setOpen(true);
	};

	const closeDocumentModal = () => {
		setOpen(false);
	};

	const handleAddCategory = async (e) => {
		e.preventDefault();
		try {
			// Prepare the payload with the state values
			const payload = {
				name,
				description,
				catImage: image,
			};

			// Call the parent component's function with the payload
			onAddCategory(payload);
			openDocumentModal();
		} catch (error) {
			console.log(error, "err");
		}
	};

	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 flex items-center justify-center z-50">
			<div className="modal-overlay absolute inset-0 bg-black opacity-50"></div>

			<div className="modal-content grid h-auto max-h-[80%] overflow-y-scroll bg-white rounded-lg shadow-md relative p-10 w-1/2">
				<div>
					<span className="text-[#0F172A] font-semibold text-[22px] mb-5">
						Add Category
					</span>
				</div>
				<div className="mt-5 border-b w-full border-[#F1F5F9]"></div>

				<form>
					{/* Document Name */}
					<div className="mb-4">
						<label
							htmlFor="documentName"
							className="text-[#15131B] text-sm font-semibold mb-2 text-left"
						>
							Document Name
						</label>
						<input
							type="text"
							id="documentName"
							name="documentName"
							className="w-full border rounded-md p-2"
							placeholder="Enter document name"
							required
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</div>

					<div className="mb-4">
						<label
							htmlFor="documentDescription"
							className="block text-[#15131B] text-sm text-left font-semibold mb-2"
						>
							Description (optional)
						</label>
						<textarea
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							id="documentDescription"
							name="documentDescription"
							className="w-full h-52 border rounded-md p-2"
							placeholder="Enter document description"
						></textarea>
					</div>

					<label className="block text-[#15131B] text-sm font-semibold mb-2">
						Category Image
					</label>
					<div>
						{selectedFile ? (
							<div className="mb-20">
								<img
									style={{
										width: "100%",
										height: "200px",
										borderRadius: "10px",
									}}
									src={URL.createObjectURL(selectedFile)}
									alt="Selected File"
								/>
							</div>
						) : (
							<div className="mb-20">
								<UploadCar
									setUpload={setUpload}
									setImage={setImage}
									upload={upload}
									image={image}
								/>
							</div>
						)}
					</div>

					{/* Submit Button */}
					<div className="text-center">
						<button
							onClick={handleAddCategory}
							className="bg-[#0468BF] hover:bg-[#CAEFFB] text-white font-bold py-1 px-6"
						>
							Save Category
						</button>
					</div>
				</form>
				{/* Modal component */}
				{open && (
					<div className="fixed inset-0 flex items-start justify-end z-50 mt-10 mr-10">
						<div className="modal-overlay absolute inset-0 "></div>

						<div className="modal-content bg-white rounded-lg shadow-md relative p-5 flex border-l-[15px] border-[#10B981]">
							<button
								className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
								onClick={closeDocumentModal}
							>
								<img width="15" height="15" src={cans} alt="rightarr" />
							</button>

							<div className="pr-6">
								<img width="35" height="35" src={done} alt="rightarr" />
							</div>
							<div>
								<p>
									You have successfully created a category. Vendors can now sign
									up to this category
								</p>
								<div>
									<span className="text-[14px] font-normal text-[#475569] leading-5"></span>
								</div>
							</div>
						</div>
					</div>
				)}

				<button
					className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
					onClick={() => onClose()}
				>
					<img width="15" height="15" src={cans} alt="rightarr" />
				</button>
			</div>
		</div>
	);
}

export default Document;
function UploadCar({ setImage, setUpload, upload, image }) {
	const [selectedFile, setSelectedFile] = useState(null);

	async function getFile(event) {
		const file = event.target.files[0];
		try {
			// Launch the document picker to select an image

			if (file) {
				setUpload(true);

				// Create a new form data object
				const formData = new FormData();

				// Append the selected image file to the form data
				formData.append("file", file);

				// Append the upload preset to the form data
				formData.append(
					"upload_preset",
					process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
				);
				formData.append("api_key", process.env.REACT_APP_CLOUDINARY_API_KEY);
				formData.append(
					"api_secret",
					process.env.REACT_APP_CLOUDINARY_API_SECRET
				);

				const response = await fetch(
					`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`,
					{
						method: "POST",
						body: formData,
					}
				);

				if (response.ok) {
					const data = await response.json();
					setImage(data.secure_url);
					setUpload(false);
					console.log(data, "fadda");
				} else {
					console.log("Image upload failed:", response.status);
					console.log(response);
					setUpload(false);
				}
			}
		} catch (error) {
			console.log("Error:", error);
			setUpload(false);
		}
		if (file) {
			setSelectedFile(file);
		}
	}
	const handleFileSelectClick = () => {
		document.getElementById("fileInput").click();
	};

	return (
		<div>
			{selectedFile ? (
				<div className="flex">
					<input
						type="file"
						accept="image/*"
						onChange={getFile}
						style={{ display: "none" }}
						id="fileInput"
					/>
					<img
						style={{
							width: "100%",
							height: "200px",
							border: "1px",
							borderRadius: "10px",
						}}
						src={URL.createObjectURL(selectedFile)}
						alt="Selected File"
					/>
					<button
						style={{
							width: "10%",
							height: "20px",
							// border: "1px",
							color: "#ffffff",
							marginLeft:"20px",
padding:"25px",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							backgroundColor: "#0468BF",
							borderRadius: "10px",
						}}
						onClick={handleFileSelectClick}
					>
						Change Image
					</button>
				</div>
			) : (
				<div>
					<input
						type="file"
						accept="image/*"
						onChange={getFile}
						style={{ display: "none" }}
						id="fileInput"
					/>
					<label htmlFor="fileInput">
						<div
							style={{
								width: "100%",
								height: "200px",
								// border: "1px",

								cursor: "pointer",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
								border: "2px dashed #0468BF",
								borderRadius: "10px",
							}}
						>
							<p
								style={{
									color: "#0468BF",
									paddingTop: "8px",
									fontWeight: "500",
								}}
							>
								{upload ? "uploading...." : "Browse photo"}
							</p>
						</div>
					</label>
				</div>
			)}
		</div>
	);
}
