/** @format */

import React, { useState, useEffect } from "react";
import trade from "../assets/trade.png";
import "../atoms/purchase.css";

import Layout from "../atoms/layout";

import { useNavigate } from "react-router-dom";
import { getUser } from "../services/requests";
import Card from "../atoms/Card";
import Loader from "../atoms/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
const Users = () => {
	const navigate = useNavigate();
	const [selectedRows, setSelectedRows] = useState([]);
	const [checkedRowsData, setCheckedRowsData] = useState([]);
	const [processedData, setProcessedData] = useState();
	const [search, setSearch] = useState("");
	const [user, setUser] = useState([]);
	const [loading, setLoading] = useState(true);
	const data = [
		{
			customerName: "John Doe",
			orderDate: "2023-09-15",
			orderType: "Online",
			trackingID: "TRK12345",
			orderTotal: "₦2,500",
			action: "edit",
			status: "Pending",
		},
		{
			customerName: "Jane Smith",
			orderDate: "2023-09-14",
			orderType: "In-Store",
			trackingID: "TRK67890",
			orderTotal: "₦1,800",
			action: "delete",
			status: "Completed",
		},
		// Add more data entries as needed
	];
	const toggleRowSelection = (index, rowData) => {
		const updatedRows = selectedRows.includes(index)
			? selectedRows.filter((rowIndex) => rowIndex !== index)
			: [...selectedRows, index];

		setSelectedRows(updatedRows);

		const updatedCheckedRowsData = updatedRows.map(
			(rowIndex) => data[rowIndex]
		);

		setCheckedRowsData(updatedCheckedRowsData);
		console.log(updatedCheckedRowsData, "checkedRowsData");
		sessionStorage.setItem("selected", JSON.stringify(updatedCheckedRowsData));

		// onRowSelection(updatedCheckedRowsData); // Call the onRowSelection with updatedRows
	};

	useEffect(() => {
		const updatedCheckedRowsData = selectedRows.map(
			(rowIndex) => data[rowIndex]
		);
		setCheckedRowsData(updatedCheckedRowsData);

		// Log inside the useEffect
		console.log(data, "data");
	}, []);
	useEffect(() => {
		const updatedCheckedRowsData = selectedRows.map(
			(rowIndex) => data[rowIndex]
		);

		setProcessedData(updatedCheckedRowsData);
		sessionStorage.setItem(
			"checkedRowsData",
			JSON.stringify(updatedCheckedRowsData)
		);
	}, []);

	async function getUsers() {
		try {
			const result = sessionStorage.getItem("myToken");

			const newToken = JSON.parse(result);
			const { data } = await getUser(newToken);
			setUser(data?.data);
			setLoading(false);
		} catch (err) {
			setLoading(false);
			console.error(err);
		}
	}

	useEffect(() => {
		getUsers();
	}, []);

	const formatDateAndTime = (dateTimeString) => {
		const dateTime = new Date(dateTimeString);

		// Separate date and time
		const date = dateTime.toLocaleDateString(undefined, {
			year: "numeric",
			month: "long",
			day: "numeric",
		});
		const time = dateTime.toLocaleTimeString(undefined, {
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
		});

		return { date, time };
	};
	const [searchResults, setSearchResults] = useState([]);

	const handleSearch = (event) => {
		const searchText = event.target.value;
		setSearch(searchText);

		const filteredData = user?.filter((user) =>
			user?.username?.toLowerCase().includes(searchText.toLowerCase())
		);

		setSearchResults(filteredData);
	};
	const itemsPerPage = 4; // Change this value based on your requirement
	const [currentPage, setCurrentPage] = useState(0);

	const paginatedData = user
		.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
		.map((item) => {
			console.log(item, "datasss");
			return {
				productDetails: item,
			};
		});
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const totalPages = Math.ceil(user.length / itemsPerPage);
	return (
		<div>
			{loading ? (
				<Loader />
			) : (
				<Layout label={"Customer"}>
					<div className="flex justify-end mt-5 mx-10">
						{/* <RedButton label={"Suspend customer"} /> */}
					</div>
					<div className="mx-10    mt-10 mb-10 lg:flex sm:grid gap-8">
						<Card
							imageSource={trade}
							description="Total Customer"
							// description3='Suspended Customer'
							// descriptionSub3='0'
							amount={user?.length}
							iconClass="fas fa-info-circle" // Replace with your FontAwesome icon class
							iconLabel="Information"
						/>
						<Card
							imageSource={trade}
							// description='Total Customer'
							description="Suspended Customer"
							amount="0"
							// amount={user?.length}
							iconClass="fas fa-info-circle" // Replace with your FontAwesome icon class
							iconLabel="Information"
						/>
					</div>
					<div className="rounded-lg shadow-lg bg-white my-20 mx-10">
						<div className="px-10 py-5">
							<div className=" bg-white   px-10 py-5 w-[100%] justify-between ">
								<div className=" bg-white w-full justify-between flex h-full">
									<div className="flex w-3/4">
										<form>
											<label
												for="default-search"
												class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white w-full"
											>
												Search
											</label>
											<div class="relative w-96">
												<div class="absolute w-full inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
													<svg
														aria-hidden="true"
														class="w-5 h-5 text-gray-500 dark:text-gray-400"
														fill="none"
														stroke="currentColor"
														viewBox="0 0 24 24"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
															d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
														></path>
													</svg>
												</div>
												<input
													value={search}
													onChange={handleSearch}
													type="search"
													id="default-search"
													class="block w-full p-2 pl-10  text-sm text-[#000000] rounded   dark:focus:ring-blue-500 border-2 border-[#CBD5E1] "
													placeholder="Search for employee or job title"
												/>
											</div>
										</form>
									</div>
									<div className="flex w-1/4">
										<div className="relative text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
											Total Customers
											<span>({user?.length})</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="bg-gray-200">
							<div className="grid grid-cols-5 p-2 bg-gray-200 py-8 text-[#475569] font-semibold ml-10">
								<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
									Customer Name
								</div>
								<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
									Email address
								</div>
								<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
									Date joined
								</div>

								<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
									Status
								</div>
							</div>
						</div>
						{search === ""
							? paginatedData?.map((row, index) => {
									console.log(user, "rows");
									return (
										<div
											className={`ml-10 grid grid-cols-5 py-4 border-b`}
											key={index}
										>
											<div className=" gap-2 flex text-left text-sm">
												<img
													className="w-9 h-9 rounded-md"
													src={row.productDetails.photo}
													alt={"imageAlt"}
												/>
												<button
													className="focus:outline-none text-[#2563EB] font-medium"
													onClick={() => {
														sessionStorage.setItem(
															"selectedIDs",
															JSON.stringify([
																row.productDetails.userId ||
																	row?.productDetails.id,
															])
														);
														navigate("/userdetails");
													}}
												>
													{row.productDetails.username}
												</button>
											</div>
											{/* prettier-ignore */}
											<div className='text-left text-sm whitespace-pre-line break-words'>{row.productDetails.email}</div>
											<div className="text-left text-sm">
												{formatDateAndTime(row?.productDetails.createdAt).date}
											</div>

											<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
												{row.productDetails.action}
											</div>
										</div>
									);
							  })
							: searchResults?.map((row, index) => {
									return (
										<div
											className={`grid grid-cols-6  gap-3 py-4 border-b`}
											key={index}
										>
											<div className="cell ml-3">
												<input
													type="checkbox"
													checked={selectedRows.includes(index)}
													onChange={() => toggleRowSelection(index, row)}
												/>
											</div>
											<div className="text-left text-sm">
												<button
													className="focus:outline-none text-[#2563EB] font-medium"
													onClick={() => {
														sessionStorage.setItem(
															"selectedIDs",
															JSON.stringify([row.userId || row?.id])
														);
														navigate("/userdetails");
													}}
												>
													{row.username}
												</button>
											</div>
											<div className="text-left text-sm">{row.email}</div>
											<div className="text-left text-sm">
												{formatDateAndTime(row?.createdAt).date}
											</div>
											<div className="text-left text-sm">{row.trackingID}</div>

											<div className="text-[#007BE0]   text-[12px] font-semibold leading-7 mx-10">
												{row.action}
											</div>
										</div>
									);
							  })}
						<div className="mb-5">
							<button
								onClick={() => handlePageChange(currentPage - 1)}
								disabled={currentPage === 0}
							>
								<FontAwesomeIcon icon={faChevronLeft} /> Previous
							</button>

							<span>{`Page ${currentPage + 1} of ${totalPages}`}</span>

							<button
								onClick={() => handlePageChange(currentPage + 1)}
								disabled={currentPage === totalPages - 1}
							>
								Next <FontAwesomeIcon icon={faChevronRight} />
							</button>
						</div>
						<div className="p-5 flex items-center justify-between mt-5"></div>
					</div>
				</Layout>
			)}
		</div>
	);
};

export default Users;
