/** @format */

import React, { useEffect, useState } from "react";
import Layout from "../atoms/layout";
import { useNavigate } from "react-router-dom";
import { getOrder } from "../services/requests";
import BlueCard from "../atoms/BlueCard";
import trade from "../assets/trade.png";
import Card from "../atoms/Card";
import Loader from "../atoms/Loader";
import Sidebar from "../atoms/layout/SideBar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
function OrderSummary() {
	const navigate = useNavigate();
	const [orderData, setOrderData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pending, setPending] = useState("");
	const [completed, setCompleted] = useState("");
	const [riderDelivered, setRiderDelivered] = useState("");
	async function getOrders() {
		setLoading(true);
		try {
			const result = sessionStorage.getItem("myToken");

			const newToken = JSON.parse(result);
			const { data } = await getOrder(newToken);

			setOrderData(data?.data);
			setLoading(false);
			const pending = data?.data?.filter(
				(item) => item.order_status === "pending"
			);
			const received = data?.data?.filter(
				(item) => item.order_status === "received"
			);
			const vendorAcceptance = data?.data?.filter(
				(item) => item.order_status === "vendorAcceptance"
			);
			setPending(pending);
			setCompleted(received);
			setRiderDelivered(vendorAcceptance);
			console.log(pending, "pen");
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	}
	const [selectedStatus, setSelectedStatus] = useState("Status");

	// Function to handle changes in the dropdown
	const handleStatusChange = (event) => {
		setSelectedStatus(event.target.value);
	};
	useEffect(() => {
		getOrders();
	}, []);
	const formatDateAndTime = (dateTimeString) => {
		const dateTime = new Date(dateTimeString);

		// Separate date and time
		const date = dateTime.toLocaleDateString(undefined, {
			year: "numeric",
			month: "long",
			day: "numeric",
		});
		const time = dateTime.toLocaleTimeString(undefined, {
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
		});

		return { date, time };
	};
	const [search, setSearch] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const handleSearch = (event) => {
		const searchText = event.target.value;
		setSearch(searchText);

		const filteredData = orderData?.filter((user) =>
			user?.product_details?.name
				?.toLowerCase()
				.includes(searchText.toLowerCase())
		);

		setSearchResults(filteredData);
	};
	const [isSmallScreen, setIsSmallScreen] = useState(false);

	// Check screen size on mount and on window resize
	const handleResize = () => {
		setIsSmallScreen(window.innerWidth <= 768); // Adjust the threshold according to your needs
	};

	useEffect(() => {
		handleResize(); // Initial check
		window.addEventListener("resize", handleResize);

		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	const filteredData =
		search === ""
			? orderData?.filter(
					(row) =>
						selectedStatus === "Status" || row.order_status === selectedStatus
			  )
			: searchResults?.filter(
					(row) =>
						selectedStatus === "Status" || row.order_status === selectedStatus
			  );

	// Use filteredData in the map function instead of orderData or searchResults
	const itemsPerPage = 4; // Change this value based on your requirement
	const [currentPage, setCurrentPage] = useState(0);

	const paginatedData = filteredData
		.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
		.map((item) => {
			console.log(item, "datasss");
			return {
				productDetails: item.product_details,
				createdAt: item.createdAt,
				order_status: item.order_status,
				customer_details: item.customer_details,
				id: item.id,
				customer_address: item.customer_address,
			};
		});
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	const totalPages = Math.ceil(filteredData.length / itemsPerPage);
	console.log(orderData, "order data");
	return (
		<div className="">
			{isSmallScreen ? (
				// Render alternative layout for small screens
				<div className="overflow-y-hidden">
					<Sidebar />
					{loading ? (
						<Loader />
					) : (
						<div className="mx-auto p-4 overflow-y-hidden">
							<div>
								<div className="mx-10 mt-10 mb-10 lg:flex sm:grid gap-8">
									<Card
										description="Total Orders"
										iconClass="fas fa-info-circle"
										iconLabel="Information"
										amount={orderData?.length}
									/>
									<Card
										// imageSource={icon2}
										// description="Customers"
										description="Pending Orders"
										iconClass="fas fa-info-circle"
										iconLabel="Information"
										amount={pending?.length}
									/>
									<BlueCard
										imageSource={trade}
										// description="Total Orders"
										description="Completed Orders"
										amount={completed?.length}
										// amount={orderData?.length}
										iconClass="fas fa-info-circle" // Replace with your FontAwesome icon class
										iconLabel="Information"
									/>
								</div>

								<div className="rounded-lg shadow-lg bg-white lg:flex grid lg:w-full md:w-1/2">
									<div className="px-10 py-5">
										<div>
											<div className="lg:flex grid">
												<form>
													<label
														for="default-search"
														class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white w-full"
													>
														Search
													</label>
													<div class="relative sm:w-full md:w-96">
														<div class="absolute w-full inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
															<svg
																aria-hidden="true"
																class="w-5 h-5 text-gray-500 dark:text-gray-400"
																fill="none"
																stroke="currentColor"
																viewBox="0 0 24 24"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	stroke-linecap="round"
																	stroke-linejoin="round"
																	stroke-width="2"
																	d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
																></path>
															</svg>
														</div>
														<input
															value={search}
															onChange={handleSearch}
															type="search"
															id="default-search"
															class="block w-full p-2 pl-10  text-sm text-[#000000] rounded   dark:focus:ring-blue-500 border-2 border-[#CBD5E1] "
															placeholder="Search for employee or job title"
														/>
													</div>
												</form>
											</div>
										</div>
									</div>
									<div className="grid grid-cols-4 p-2 bg-gray-200 py-8 text-[#475569] font-semibold">
										<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
											Product
										</div>
										<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
											Customer
										</div>
										<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
											Date Created
										</div>
										<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
											Total Price
										</div>
									</div>
									{search === ""
										? orderData?.map((row, index) => {
												return (
													<div
														className={`grid grid-cols-4 py-4 border-b`}
														key={index}
													>
														<div className=" gap-2 flex text-left text-sm">
															<img
																className="w-9 h-9 rounded-md"
																src={row.product_details?.photos[0]}
																alt={"imageAlt"}
															/>
															<button
																className="focus:outline-none text-[#2563EB] font-medium"
																onClick={() => {
																	sessionStorage.setItem(
																		"selectedIDs",
																		JSON.stringify([row.id])
																	);
																	navigate("/orderDetails");
																}}
															>
																{row.product_details?.name}
															</button>
														</div>
														<div className="text-left text-sm flex gap-2">
															{row.customer_details?.username}
														</div>

														<div className="text-left text-sm">
															{
																formatDateAndTime(
																	row?.customer_details?.createdAt
																).date
															}
														</div>
														<div className="text-left text-sm">
															₦ {row.product_details?.price}
														</div>
													</div>
												);
										  })
										: searchResults?.map((row, index) => {
												console.log(row?.vendorId, "stat");
												return (
													<div
														className={`grid grid-cols-4 py-4 border-b`}
														key={index}
													>
														<div className=" gap-2 flex text-left text-sm">
															<img
																className="w-9 h-9 rounded-md"
																src={row.product_details?.photos[0]}
																alt={"imageAlt"}
															/>
															<button
																className="focus:outline-none text-[#2563EB] font-medium"
																onClick={() => {
																	sessionStorage.setItem(
																		"selectedIDs",
																		JSON.stringify([row.id])
																	);
																	navigate("/orderDetails");
																}}
															>
																{row.product_details?.name}
															</button>
														</div>
														<div className="text-left text-sm flex gap-2">
															{/* <img
														className="w-9 h-9 rounded-md"
														src={row.customer_details?.photo}
														alt={"imageAlt"}
													/> */}
															{row.customer_details?.username}
														</div>
														{/* <div className="text-left text-sm">
													{row.productId}
												</div> */}
														<div className="text-left text-sm">
															{
																formatDateAndTime(
																	row?.customer_details?.createdAt
																).date
															}
														</div>
														<div className="text-left text-sm">
															₦ {row.product_details?.price}
														</div>
													</div>
												);
										  })}

									<div className="p-5 flex items-center justify-between mt-5"></div>
								</div>
							</div>
						</div>
					)}
				</div>
			) : (
				// Render your default layout for larger screens
				<div className="">
					{loading ? (
						<Loader />
					) : (
						<div>
							{loading ? (
								<Loader />
							) : (
								<Layout>
									<div className="container mx-auto p-4">
										<div>
											<div className="mx-10    mt-10 mb-10 lg:flex sm:grid gap-8">
												<Card
													imageSource={trade}
													description="Total Orders"
													iconClass="fas fa-info-circle"
													iconLabel="Information"
													amount={orderData?.length}
												/>
												<Card
													imageSource={trade}
													// description="Customers"
													description="Pending Orders"
													iconClass="fas fa-info-circle"
													iconLabel="Information"
													amount={pending?.length}
												/>
												<BlueCard
													imageSource={trade}
													// description="Total Orders"
													description="Completed Orders"
													amount={completed?.length}
													// amount={orderData?.length}
													iconClass="fas fa-info-circle" // Replace with your FontAwesome icon class
													iconLabel="Information"
												/>
											</div>
											<div className="rounded-lg shadow-lg bg-white my-20 mx-10">
												<div className="px-10 py-5">
													<div className=" bg-white   px-10 py-5 w-[100%] justify-between ">
														<div className=" bg-white w-full justify-between flex h-full">
															<div className="flex">
																<form>
																	<label
																		for="default-search"
																		class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white w-full"
																	>
																		Search
																	</label>
																	<div class="relative w-96">
																		<div class="absolute w-full inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
																			<svg
																				aria-hidden="true"
																				class="w-5 h-5 text-gray-500 dark:text-gray-400"
																				fill="none"
																				stroke="currentColor"
																				viewBox="0 0 24 24"
																				xmlns="http://www.w3.org/2000/svg"
																			>
																				<path
																					stroke-linecap="round"
																					stroke-linejoin="round"
																					stroke-width="2"
																					d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
																				></path>
																			</svg>
																		</div>
																		<input
																			value={search}
																			onChange={handleSearch}
																			type="search"
																			id="default-search"
																			class="block w-full p-2 pl-10  text-sm text-[#000000] rounded   dark:focus:ring-blue-500 border-2 border-[#CBD5E1] "
																			placeholder="Search"
																		/>
																	</div>
																</form>
															</div>
															<div className="flex">
																<div className="relative ml-2 rounded-md p-2 text-[#475569]">
																	<select
																		className="h-[36px] border-2 border-[#CBD5E1] w-full bg-white rounded-md py-2 pl-8 pr-3 sm:text-sm sm:leading-5"
																		value={selectedStatus}
																		onChange={handleStatusChange}
																	>
																		<option value="Status">Status</option>
																		<option value="received">Received</option>
																		<option value="pending">Pending</option>
																		<option value="vendorAcceptance">
																			vendorAcceptance
																		</option>
																	</select>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className=" bg-gray-200 ">
													<div className="ml-10 text-[#475569] font-semibold grid grid-cols-5 p-2  py-8 ">
													
														<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
															Product
														</div>
														<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
															Customer
														</div>
														{/* <div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
														Order #
													</div> */}
														<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
															Date Created
														</div>
														<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
															Total Price
														</div>
														{/* <div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
														Tracking ID
													</div> */}
														<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
															Status
														</div>
													</div>
												</div>
												{paginatedData?.map((row, index) => {
													console.log(row, "stat");
													return (
														<div
															className={` ml-10 grid grid-cols-5 py-4 border-b`}
															key={index}
														>
															<div className=" gap-2 flex text-left text-sm">
																<img
																	className="w-9 h-9 rounded-md"
																	src={row.productDetails?.photos[0]}
																	alt={"imageAlt"}
																/>
																<button
																	className="focus:outline-none text-[#2563EB] font-medium"
																	onClick={() => {
																		sessionStorage.setItem(
																			"selectedIDs",
																			JSON.stringify([row.id])
																		);
																		navigate("/orderDetails");
																	}}
																>
																	{row.productDetails?.name}
																</button>
															</div>
															<div className="text-left text-sm flex gap-2">
																<img
																	className="w-9 h-9 rounded-md"
																	src={row.customer_details?.photo}
																	alt={"imageAlt"}
																/>
																{row.customer_details?.username}
															</div>
															{/* <div className="text-left text-sm">
																{row.productId}
															</div> */}
															<div className="text-left text-sm">
																{formatDateAndTime(row?.createdAt).date}<br />{formatDateAndTime(row?.createdAt).time}
															</div>
															<div className="text-left text-sm">
																₦ {row.productDetails?.price}
															</div>

															<div className="text-left text-sm">
																{row.order_status}
															</div>
														</div>
													);
												})}
												<div className="mb-5">
													<button
														onClick={() => handlePageChange(currentPage - 1)}
														disabled={currentPage === 0}
													>
														<FontAwesomeIcon icon={faChevronLeft} /> Previous
													</button>

													<span>
														{`Page ${currentPage + 1} of ${totalPages}`}
													</span>

													<button
														onClick={() => handlePageChange(currentPage + 1)}
														disabled={currentPage === totalPages - 1}
													>
														Next <FontAwesomeIcon icon={faChevronRight} />
													</button>
												</div>
												<div className="p-5 flex items-center justify-between mt-5"></div>
											</div>
										</div>
									</div>
								</Layout>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
}

export default OrderSummary;
