/** @format */

import React, { useState, useEffect } from "react";

import "../atoms/purchase.css";
import Layout from "../atoms/layout";
import Card from "../atoms/Card";
import trade from "../assets/trade.png";
import icon2 from "../assets/icontwo.png";
import RedButton from "../atoms/RedButton";
import { useNavigate } from "react-router-dom";
import Alert from "../atoms/Alert";
import Suspension from "../atoms/layout/Suspension";
import Modal from "../atoms/Modal";
import BlueBotton from "../atoms/BlueBotton";
const RidderRequest = ({ onRowSelection, onEmployeeNameClick }) => {
	const [selectedRows, setSelectedRows] = useState([]);
	const [checkedRowsData, setCheckedRowsData] = useState([]);
	const [processedData, setProcessedData] = useState();
	const [modal, setModal] = useState(false);
	const [suspend, setSuspend] = useState(false);
	const [sucess, setSucess] = useState(false);

	const navigate = useNavigate();
	const openModal = () => {
		setModal(true);
	};
	const closeModal = () => {
		setModal(false);
	};
	const openSuspend = () => {
		setSuspend(true);
		setModal(false);
	};
	const closeSuspend = () => {
		setSuspend(false);
	};
	const openSucess = () => {
		setSucess(true);
		setSuspend(false);
		console.log("hrlllo");
	};
	const closeSucess = () => {
		setSucess(false);
	};
	const data = [
		{
			customerName: "John Doe",
			orderDate: "2023-09-15",
			orderType: "Online",
			trackingID: "TRK12345",
			orderTotal: "₦2,500",
			action: "edit",
			status: "Pending",
		},
		{
			customerName: "Jane Smith",
			orderDate: "2023-09-14",
			orderType: "In-Store",
			trackingID: "TRK67890",
			orderTotal: "₦1,800",
			action: "delete",
			status: "Completed",
		},
		// Add more data entries as needed
	];
	const toggleRowSelection = (index, rowData) => {
		const updatedRows = selectedRows.includes(index)
			? selectedRows.filter((rowIndex) => rowIndex !== index)
			: [...selectedRows, index];

		setSelectedRows(updatedRows);

		const updatedCheckedRowsData = updatedRows.map(
			(rowIndex) => data[rowIndex]
		);

		setCheckedRowsData(updatedCheckedRowsData);
		console.log(updatedCheckedRowsData, "checkedRowsData");
		sessionStorage.setItem("selected", JSON.stringify(updatedCheckedRowsData));

		onRowSelection(updatedCheckedRowsData); // Call the onRowSelection with updatedRows
	};
	const handleActionChange = (selectedAction, row) => {
		// Implement your logic here based on the selected action and the corresponding row
		console.log(`Selected action: ${selectedAction}, Row:`, row);
	};
	useEffect(() => {
		const updatedCheckedRowsData = selectedRows.map(
			(rowIndex) => data[rowIndex]
		);
		setCheckedRowsData(updatedCheckedRowsData);
	}, []);
	useEffect(() => {
		const updatedCheckedRowsData = selectedRows.map(
			(rowIndex) => data[rowIndex]
		);

		setProcessedData(updatedCheckedRowsData);
		sessionStorage.setItem(
			"checkedRowsData",
			JSON.stringify(updatedCheckedRowsData)
		);
	}, []);

	// ...

	return (
		<Layout label={"Customer"}>
			<div className="flex justify-end mt-5 mx-10 gap-4">
				{/* <div className=" flex gap-8">
					<span>Orders Number 284729367</span>
					<span>Tracking ID #3846d8w6w8awd</span>
					<span>User Since 15 May 2020 </span>
				</div> */}
			</div>
			<Alert
				onClick={openSuspend}
				label={"Yes, Approve Payroll"}
				title={"Suspend Customer"}
				content={
					<div className="text-[#64748B] font-semibold text-[16px] leading-6">
						Are you sure you want to suspend this customer
					</div>
				}
				isOpen={modal}
				onClose={closeModal}
			/>
			<Suspension
				label={"Yes, Approve Payroll"}
				title={"Suspend Customer"}
				isOpen={suspend}
				onClick={openSucess}
				onClose={closeSuspend}
			/>
			<Modal
				isOpen={sucess}
				onClose={closeSucess}
				title={"You have successfully suspended this customer"}
			/>

			<div className="rounded-lg shadow-lg bg-white my-20 mx-10">
				<div className="px-10 py-5">
					<div className=" bg-white   px-10 py-5 w-[100%] justify-between ">
						<div className=" bg-white w-full justify-between flex h-full">
							<div className="flex w-3/4">
								<form>
									<label
										for="default-search"
										class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white w-full"
									>
										Search
									</label>
									<div class="relative w-96">
										<div class="absolute w-full inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
											<svg
												aria-hidden="true"
												class="w-5 h-5 text-gray-500 dark:text-gray-400"
												fill="none"
												stroke="currentColor"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
												></path>
											</svg>
										</div>
										<input
											type="search"
											id="default-search"
											class="block w-full p-2 pl-10  text-sm text-gray-900   rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-[#CBD5E1] dark:bg-[#ffffff] dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 border-2 border-[#CBD5E1] "
											placeholder="Search for employee or job title"
											required
										/>
									</div>
								</form>
							</div>
							<div className="flex w-1/4">
								<div className="relative text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
									<select
										className="absolute inset-y-0 left-0 pl-4 pr-2 text-sm text-gray-900 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-[#CBD5E1] "
										onChange={(e) => handleActionChange(e.target.value)}
									>
										<option value="">Select Action</option>
										<option value="edit">Edit</option>
										<option value="delete">Delete</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="grid grid-cols-7 p-2 bg-gray-200 py-8 text-[#475569] font-semibold">
					<div className="cell ml-3">
						<input type="checkbox" />
					</div>
					<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
						Order Date
					</div>
					<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
						Order Type
					</div>
					<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
						Tracking ID
					</div>
					<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
						Order Total
					</div>
					<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
						Action
					</div>

					<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
						Status
					</div>
				</div>
				{data?.map((row, index) => {
					return (
						<div className={`grid grid-cols-7 py-4 border-b`} key={index}>
							<div className="cell ml-3">
								<input
									type="checkbox"
									checked={selectedRows.includes(index)}
									onChange={() => toggleRowSelection(index, row)}
								/>
							</div>
							<div className="text-left text-sm">
								<button
									className="focus:outline-none text-[#2563EB] font-medium"
									onClick={() => {
										onEmployeeNameClick(row.employeeName);
										sessionStorage.setItem(
											"selectedIDs",
											JSON.stringify([row.id])
										);
									}}
								>
									{row.customerName}
								</button>
							</div>
							<div className="text-left text-sm">{row.orderDate}</div>
							<div className="text-left text-sm">{row.orderType}</div>
							<div className="text-left text-sm">{row.trackingID}</div>
							<div className="text-left text-sm">{row.orderTotal}</div>

							<div className="text-[#007BE0]   text-[12px] font-semibold leading-7 mx-10">
								{row.status}
							</div>
						</div>
					);
				})}
				<div className="p-5 flex items-center justify-between mt-5">
					{/* ... (Pagination and Show rows elements remain the same) */}
				</div>
			</div>
		</Layout>
	);
};

export default RidderRequest;
