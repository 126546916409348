/** @format */

import React, { useState, useEffect } from "react";

import "./purchase.css";
import { FaTimes } from "react-icons/fa";
import Layout from "./layout";
import Card from "./Card";
import trade from "../assets/trade.png";
import icon2 from "../assets/icontwo.png";

const Orders = ({ onRowSelection, onEmployeeNameClick }) => {


	const [selectedRows, setSelectedRows] = useState([]);
	const [checkedRowsData, setCheckedRowsData] = useState([]);
	const [processedData, setProcessedData] = useState();
	const data = [
		{
			customerName: "John Doe",
			orderDate: "2023-09-15",
			orderType: "Online",
			trackingID: "TRK12345",
			orderTotal: "₦2,500",
			action: "edit",
			status: "Pending",
		},
		{
			customerName: "Jane Smith",
			orderDate: "2023-09-14",
			orderType: "In-Store",
			trackingID: "TRK67890",
			orderTotal: "₦1,800",
			action: "delete",
			status: "Completed",
		},
		// Add more data entries as needed
	];
	const toggleRowSelection = (index, rowData) => {
		const updatedRows = selectedRows.includes(index)
			? selectedRows.filter((rowIndex) => rowIndex !== index)
			: [...selectedRows, index];

		setSelectedRows(updatedRows);

		const updatedCheckedRowsData = updatedRows.map(
			(rowIndex) => data[rowIndex]
		);

		setCheckedRowsData(updatedCheckedRowsData);
		console.log(updatedCheckedRowsData, "checkedRowsData");
		sessionStorage.setItem("selected", JSON.stringify(updatedCheckedRowsData));

		onRowSelection(updatedCheckedRowsData); // Call the onRowSelection with updatedRows
	};
	const handleActionChange = (selectedAction, row) => {
		// Implement your logic here based on the selected action and the corresponding row
		console.log(`Selected action: ${selectedAction}, Row:`, row);
	};
	useEffect(() => {
		const updatedCheckedRowsData = selectedRows.map(
			(rowIndex) => data[rowIndex]
		);
		setCheckedRowsData(updatedCheckedRowsData);

		// Log inside the useEffect
		console.log(data, "data");
	}, []);
	useEffect(() => {
		const updatedCheckedRowsData = selectedRows.map(
			(rowIndex) => data[rowIndex]
		);

		setProcessedData(updatedCheckedRowsData);
		sessionStorage.setItem(
			"checkedRowsData",
			JSON.stringify(updatedCheckedRowsData)
		);
	}, []);

	// ...

	return (
		<Layout>
			<div className="mx-10    mt-10 mb-10 flex gap-8">
					<Card
					
						imageSource={icon2}
						description="Sales"
						description2="Volume"
						iconClass="fas fa-info-circle" // Replace with n
						iconLabel="Information"
						numbers="3454"
						amount="₦4,000,000"
					/>
					<Card
         
						imageSource={icon2}
						description="Customers"
						description2="Active"
						iconClass="fas fa-info-circle" // Replace with your FontAwesome icon class
						iconLabel="Information"
						numbers="345"
						amount="₦4,000,000"
					/>
					<Card
						imageSource={trade}
						description="All Orders"
						description2="Pending"
						description3="Completed"
						descriptionSub3="456"
						numbers="345"
						amount="₦4,000,000"
						iconClass="fas fa-info-circle" // Replace with your FontAwesome icon class
						iconLabel="Information"
					/>
				</div>
			<div className="rounded-lg shadow-lg bg-white my-20 mx-10">
				<div className="px-10 py-5">
					<div className=" bg-white   px-10 py-5 w-[100%] justify-between ">
						<div className=" bg-white w-full justify-between flex h-full">
							<div className="flex">
								<form>
									<label
										for="default-search"
										class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white w-full"
									>
										Search
									</label>
									<div class="relative w-96">
										<div class="absolute w-full inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
											<svg
												aria-hidden="true"
												class="w-5 h-5 text-gray-500 dark:text-gray-400"
												fill="none"
												stroke="currentColor"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
												></path>
											</svg>
										</div>
										<input
											type="search"
											id="default-search"
											class="block w-full p-2 pl-10  text-sm text-gray-900   rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-[#CBD5E1] dark:bg-[#ffffff] dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 border-2 border-[#CBD5E1] "
											placeholder="Search for employee or job title"
											required
										/>
									</div>
								</form>
							</div>
							<div className="flex">
								

								<div className="relative ml-2 rounded-md p-2 text-[#475569 ">
									<select className="h-[36px]  border-2 border-[#CBD5E1]  w-full bg-white  rounded-md py-2 pl-8 pr-3 sm:text-sm sm:leading-5">
										<option>A-Z</option>
										<option>Z-A</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="grid grid-cols-8 p-2 bg-gray-200 py-8 text-[#475569] font-semibold">
					<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider"></div>
					<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
						Customer Name
					</div>
					<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
						Order Date
					</div>
					<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
						Order Type
					</div>
					<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
						Tracking ID
					</div>
					<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
						Order Total
					</div>
					<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
						Action
					</div>
					<div className="text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
						Status
					</div>
				</div>
				{data?.map((row, index) => {
					return (
						<div className={`grid grid-cols-8 py-4 border-b`} key={index}>
							<div className="cell ml-3">
								<input
									type="checkbox"
									checked={selectedRows.includes(index)}
									onChange={() => toggleRowSelection(index, row)}
								/>
							</div>
							<div className="text-left text-sm">
								<button
									className="focus:outline-none text-[#333333] font-medium"
									// onClick={() => {
									// 	onEmployeeNameClick(row.employeeName);
									// 	sessionStorage.setItem(
									// 		"selectedIDs",
									// 		JSON.stringify([row.id])
									// 	);
									// }}
								>
									{row.customerName}
								</button>
							</div>
							<div className="text-left text-sm">
							{row.orderDate}
							</div>
							<div className="text-left text-sm">
								 {row.orderType}
							</div>
							<div className="text-left text-sm">{row.trackingID}</div>
							<div className="text-left text-sm">{row.orderTotal}</div>

							<div className="relative text-left text-xs font-semibold text-[#475569] uppercase tracking-wider">
								<select
									className="absolute inset-y-0 left-0 pl-4 pr-2 text-sm text-gray-900 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-[#CBD5E1] "
									onChange={(e) => handleActionChange(e.target.value)}
								>
									<option value="">Select Action</option>
									<option value="edit">Edit</option>
									<option value="delete">Delete</option>
								</select>
							</div>

							<div className="text-[#FCA800] bg-[#FEF4CB] text-[12px] font-semibold leading-7 mx-5">{row.status}</div>
						</div>
					);
				})}
				<div className="p-5 flex items-center justify-between mt-5">
					{/* ... (Pagination and Show rows elements remain the same) */}
				</div>
			</div>
		</Layout>
	);
};

export default Orders;
