/** @format */

import React from "react";

const Card = ({
	imageSource,
	imageAlt,

	description,

	amount,

	onClick, // Add onClick prop
}) => {
	return (
		<div
			onClick={onClick}
			className=" rounded-md h-53 flex-1 overflow-hidden shadow-lg bg-[#ffffff]  mb-10 sm:mb-4 sm:grid"
		>
			<img
				className="my-3 mx-3 w-10 h-10 rounded-lg "
				src={imageSource}
				alt={imageAlt}
			/>
			<div className="px-6 py-4 w-full">
				<div className="flex justify-between">
					<div style={{ flex: 1 }}>
						<p className="text-[#4F4F4F] text-[14px]">
							{description}
							<p className="text-[#333333] text-[16px] font-semibold leading-7">
								{amount}
							</p>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Card;
