/** @format */

import React, { useState } from "react";
import cans from "../../src/assets/cans copy.png";
// Import the icon's CSS
import { useNavigate } from "react-router-dom";
import BlueBotton from "./BlueBotton";
// import RedButton from "./RedButton";
// import BlueBotton from "./BlueBotton";
// import BlueBotton from "./BlueBotton";
// import LineButton from "./LineButton";
function NoSuspension({
	isOpen,
	onClose,
	children,
	title,
	content,
	label,
	onClick,
}) {
	const navigate = useNavigate();
	if (!isOpen) return null;

	return (
		<div className="p-5 fixed inset-0 flex items-center justify-center z-50">
  <div className="modal-overlay absolute inset-0 bg-black opacity-50"></div>

  <div className="modal-content bg-white rounded-lg shadow-md relative p-10 flex">
    <div>
      <p className=" text-[24px] font-bold text-[#15131B] leading-5 mb-5">
        {title}
      </p>
      <p className="mt-10 text-left text-[18px] font-bold text-[#15131B] leading-5 mb-5">
        Reason for Unsuspension 
      </p>
      <p className=" text-left mt-5 mb-3 text-[#15131B] text-[14px] front-semibold ">Tell the user why you’re Unsuspending them</p>
      <div className="mb-5">
        <textarea className="h-40 w-96 border"></textarea>
      </div>
      <BlueBotton  onClick={onClick}	label={label}/>
    </div>

    <button
      className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
      onClick={onClose}
    >
      <img width="20" height="20" src={cans} alt="rightarr" />
    </button>
    {children}
  </div>
</div>

	);
}

export default NoSuspension;
